/**
 *  GAMIFICATION
 *  and BREATHING-BALL animation
 *  file: assets/scss/components/_gamification.scss
 */

// #GamificationHeader
.gamification-header {
    position: fixed;
 		width: inherit;
    transition: max-width .2s linear;
 		left: auto;
    // max-width: 2050px;
 		height: 80px;
 		border-bottom: 2px solid $gray-200;
    background-color: rgba($white,1);
    z-index: 10;
    overflow: hidden;
 		@each $breakpoint in map-keys($grid-breakpoints)
 		{
 				@include media-breakpoint-up($breakpoint, $grid-breakpoints)
 				{
 						$header-height: map-get($header-heights, $breakpoint);
 						top: #{$header-height}px;
 				}
 		}

    .animation-container {
        position: relative;
        top: 0;
        left: 1.5rem;
        width: 467px;
        height: 127px;
        background-color: rgba($white,0);
        .canvas {
            display: block;
            position: absolute;
            background-color: rgba($white,0);
        }
        .animation-overlay {
            pointer-events: none;
            overflow: hidden;
            width: inherit;
            height: inherit;
            position: absolute;
            left: 0;
            right: 0;
        }
        transform-origin: left top;
        transform: scale(.65);
    }
}

.sidebar-hidden .gamification-header {
    max-width: $page-max-width;
}

// #Exercises
.exercises {
    position:  relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    transform: translateY(-100vh);
    top: 0;
    visibility: hidden;
    @include gradient-y(rgba($white,.75), rgba($white,0), 75%, 100%);
    @each $breakpoint in map-keys($grid-breakpoints)
 		{
 				@include media-breakpoint-up($breakpoint, $grid-breakpoints)
 				{
 						$header-height: map-get($header-heights, $breakpoint);
 						padding-top: #{$header-height}px;
            padding-bottom: #{$header-height}px;
 				}
 		}
}

.start-exercise-btn {
    position: fixed;
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: $primary;
    color: $white;
    margin: -50px;
    top: 50%;
    left: 50%;
    font-size: 1.4rem;
    line-height: 1.1em;
    text-align: center;
    transition: all .2s ease-in;
    &:hover,
    &:focus {
        color: $white;
        transform: scale(1.15) !important;
    }
}

// #Gamification
.gamification-box {
		// position: absolute;
		max-width: 100%;
		width: 400px;
		// left: inherit;
		// top: 55%;
		margin-left: 0;
    margin-right: 0;
    // margin-top: -330px;
		overflow: hidden;
		@include media-breakpoint-up(sm) {
				// transform: scale(1.1);
        // margin-left: 18%;
        // margin-right: 18%;
		}
		@include media-breakpoint-up(md) {
				// transform: scale(1.2);
        // margin-left: 10%;
        // margin-right: 10%;
		}
		@include media-breakpoint-up(lg) {
				// transform: scale(1.3);
        // margin-left: 20%;
        // margin-right: 20%;
		}
		@include media-breakpoint-up(xl) {
				// transform: scale(1.4);
        // margin-left: 27%;
        // margin-right: 27%;
		}
    @include media-breakpoint-up(xxl) {
				// transform: scale(1.4);
        // margin-left: 35%;
        // margin-right: 35%;
		}
}

.exercises .gamification-box {
    position: absolute;
    transform: scale(1);
}

// #Gamification > .gamification-body
    .gamification-body {
        position: relative;
        .dog-icon,
        .checkpoint {
            position: absolute;
        }
    }

// #Gamification > .gamification-body > svg
        .svg-dot {
            fill: $gray-300;
            &.fill-color-1 { fill: $color-1; }
            &.fill-color-2 { fill: $color-2; }
            &.fill-color-3 { fill: $color-3; }
            &.fill-color-4 { fill: $color-4; }
            &.fill-color-5 { fill: $color-5; }
            &.fill-color-6 { fill: $color-6; }
        }


.dog {
    display: block;
    width: 84px;
    height: 78px;
    margin-top: -39px;
    &.dog-1 {
        @include svg('dog-01');
    }
    &.dog-2 {
        @include svg('dog-02');
    }
    &.dog-3 {
        @include svg('dog-03');
    }
    &.dog-4 {
        @include svg('dog-04');
    }
    &.dog-5 {
        @include svg('dog-05');
    }
    &.dog-6 {
        @include svg('dog-06');
    }
    &.dog-muted {
        @include svg('dog-muted', auto, auto, .5);
    }
}

.flag {
    display: inline-block;
    width: 40px;
    height: 60px;
    margin-top: -60px;
    margin-left: -20px;
    margin-right: -20px;
    @include svg('flag');
}

.day-counter {
    display: inline-block;
    height: 100%;
    width: auto;
    .current,
    .total {
        font-family: $font-family-sans-serif;
        font-size: 1.1rem;
        color: $gray-500;
    }
    &.checkpoint-state-1 .current {
        color: $color-1;
    }
    &.checkpoint-state-2 .current {
        color: $color-2;
    }
    &.checkpoint-state-3 .current {
        color: $color-3;
    }
    &.checkpoint-state-4 .current {
        color: $color-4;
    }
    &.checkpoint-state-5 .current {
        color: $color-5;
    }
    &.checkpoint-state-6 .current {
        color: $color-6;
    }
}

.checkpoint {
    display: block;
    width: 25px;
    height: 25px;
    margin-left: -12.5px;
    margin-top: -12.5px;
    transition: all .3s linear;
    &.checkpoint-01 {
        @include svg('target-01', auto, auto, .5);
    }
    &.checkpoint-02 {
        @include svg('target-02', auto, auto, .5);
    }
    &.checkpoint-03 {
        @include svg('target-03', auto, auto, .5);
    }
    &.checkpoint-04 {
        @include svg('target-04', auto, auto, .5);
    }
    &.checkpoint-05 {
        @include svg('target-05', auto, auto, .5);
    }
    &.checkpoint-06 {
        @include svg('target-06', auto, auto, .5);
    }
    &:before {
        content: '';
        @include svg('flag', auto, auto, .5);
        position: absolute;
        display: block;
        width: 20px;
        height: 30px;
        margin-top: -15px;
        margin-left: -10px;
        margin-right: -10px;
        left: 50%;
        top: -10px;
    }

    // .day-label
    > .day-label {
        position: absolute;
        left: 32px;
        font-size: 1rem;
        color: $gray-500;
        transition: opacity .3s linear;
        opacity: 0;
    }
    // .day-label
    > .dog-label {
        position: absolute;
        right: 32px;
        bottom: -16px;
        font-size: 75%;
        color: $gray-500;
        // display: none;
        transition: opacity .3s linear;
        opacity: 0;
    }
    &:nth-child(even) > .day-label {
        left: auto;
        right: 32px;
    }
    &:nth-child(even) > .dog-label {
        right: auto;
        left: 32px;
    }

    // .dog
    > .dog {
        position: absolute;
        left: 26px;
        bottom: 0;
        transform: scaleX(-1);
        transition: opacity .3s linear;
        opacity: 0;
        &.dog-1 {
            @include svg('dog-01', auto, auto, .6);
            // display: none;
        }
        &.dog-2 {
            @include svg('dog-02', auto, auto, .6);
            // display: none;
        }
        &.dog-3 {
            @include svg('dog-03', auto, auto, .6);
            // display: none;
        }
        &.dog-4 {
            @include svg('dog-04', auto, auto, .6);
            // display: none;
        }
        &.dog-5 {
            @include svg('dog-05', auto, auto, .6);
            // display: none;
        }
        &.dog-6 {
            @include svg('dog-06', auto, auto, .6);
            // display: none;
        }
        &.dog-muted {
            opacity: 1;
            @include svg('dog-muted', auto, auto, .5);
        }
    }
    &:nth-child(odd) > .dog {
        left: auto;
        right: 26px;
        transform: scaleX(1);
    }

    &:hover,
    &:focus,
    &.hover {
        cursor: pointer;
        transform: scale(1.05);
        > .dog-muted {
            opacity: 0 !important;
            // display: none;
        }
        > .dog-label {
            opacity: 1;
            // display: inline-block;
        }
        > .dog {
            &.dog-1,
            &.dog-2,
            &.dog-3,
            &.dog-4,
            &.dog-5,
            &.dog-6 {
                opacity: 1;
                // display: block;
            }
        }
    }
}

// Gamification dog-icons (indicator for checkpoints in the past)
.dog-icon {
    display: block;
    width: 42px;
    height: 56px;
    margin-left: -21px;
    margin-top: -28px;
    visibility: hidden;
    transition: all .2s ease-in;
    &.dog-icon-01 {
        @include svg('dog-icon-01', auto, auto, .5);
    }
    &.dog-icon-02 {
        @include svg('dog-icon-02', auto, auto, .5);
    }
    &.dog-icon-03 {
        @include svg('dog-icon-03', auto, auto, .5);
    }
    &.dog-icon-04 {
        @include svg('dog-icon-04', auto, auto, .5);
    }
    &.dog-icon-05 {
        @include svg('dog-icon-05', auto, auto, .5);
    }
    &.dog-icon-06 {
        @include svg('dog-icon-06', auto, auto, .5);
    }
    &:hover,
    &:focus {
        cursor: pointer;
        transform: scale(1.1);
    }
}

// Gamification Modal (.progress-modal)
.progress-modal {

    .close {
        border-radius: 10px;
        background-color: $white;
        width: 20px;
        height: 20px;
        box-shadow: none;
        font-size: 84%;
        color: $gray-700;
        .icon {

        }
    }
    .modal-content {
        border-radius: 0;
        border: 2px dashed $gray-500;
        background-color: $gray-100;
        background-clip: inherit;
        color: $gray-800;
    }
    .dog-box {
        padding-top: 3rem;
    }
    .dog-box .row {
        background-color: $white;
        max-height: 54px;

        .dog {
            margin-left: 2rem;
            transform: scale(1.4);
        }
    }
    .copy-box {
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 1rem;
        font-size: 90%;
    }
    .h1 {
        font-weight: 300;
        line-height: 1.2em;
    }

}

// BREATHING-BALL
.breathing-ball {
    .layer {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        z-index: 10;
    }

    .title {
        position: absolute;
        text-align: center;
        width: 300px;
        margin-left: -150px;
        left: 50%;
    }

    .instruction {
        position: absolute;
        display: block;
        font-family: $font-family-condensed;
        text-transform: uppercase;
        color: $primary;
        text-align: center;
        line-height: 1;
        font-weight: 600;
        width: 250px;
        margin-left: -125px;
        opacity: 0;
    }

    #hold_1,
    #hold_2 { left: 51%; }

    #hold_1 { top: 17%; }
    #hold_2 { bottom: 17%; color: $white; }

    #breath_in,
    #breath_out { top: 49%; }

    #breath_in { left: 85%; }
    #breath_out { left: 15%; }
}
