/**
 *  FORM
 *  assets/scss/bootstrap/_form.scss
 */

/** BASIC FORM */
.form,
.form-control {
    // font-family: $font-family-sans-serif;
}

/**
 *  BASIC FORM-COLUMN-CLASSES
 *  f.e. for 'register-form' and 'login-form'
 *
 *  @templates:
 *  -> templates/forms/*
 */

// column-class form-labels
.col-form-label {
    @include make-col-ready();
    @include make-col(3);
    text-align: right;
    @include media-breakpoint-down(sm) {
        display: none;
    }
}
.custom-control-label,
.col-form-label,
.form-check-label {
    font-family: $font-family-condensed;
    font-weight: 400;
    color: $gray-800;
    &.text-sm {
        line-height: 1.7;
    }
    &.required {
        color: $gray-950;
        &:before{
            content: '* ';
        }
        @include media-breakpoint-down(md) {
            &:before{
                content: '';
            }
            &:after {
                content: ' *'
            }
        }
    }
    &.checkbox-inline {
        &.required {
            &:before{
                display: none;
            }
            &:after {
                content: '*'
            }
        }
    }
}

// column-class for basic form-input
.col-form-input {
    @include make-col-ready();
    @include make-col(9);
    @include media-breakpoint-down(sm) {
        @include make-col(12);
    }

    // use .offset on .col-form-input if it's not following a .col-form-label
    &.offset {
        @include make-col-offset(3);
        @include media-breakpoint-down(sm) {
            @include make-col-offset(0);
        }
    }

    // tinymce editor
    .mce-panel {
        border-color: $input-border-color;
        .mce-edit-area {
            border-right-width: 1px !important;
        }
    }
    &.col-form-input-radio,
    &.col-form-input-checkbox {
        padding-top: calc(#{$input-padding-y} + #{$input-border-width});
        padding-bottom: calc(#{$input-padding-y} + #{$input-border-width});
    }
}

/** ICN-DONUT */

.icn-donut {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    vertical-align: text-bottom;
    border: .35rem solid rgba($white,.25);
    background-color: transparent;
    margin-right: .5rem;
    @each $color, $value in $theme-colors {
        &.icn-donut-#{$color} {
            border: .35rem solid darken($value, 15%);
        }
    }
}

/** INPUT-GROUPS */
.input-group,
.btn-group {
    .btn,
    .input-group-text {
        font-family: $font-family-condensed;
        font-weight: 400;
    }

    .input-group-text {
        font-size: 100%;
        text-transform: uppercase;

        @each $color, $value in $theme-colors {
            &.#{$color} {
                @include gradient-y( mix($white, $value, 35%), $value);
                color: rgba($white, 1);
                text-shadow: 1px 1px 0px lighten($value, 10%);
                border-color: darken($value, 10%);
            }
        }
    }
}
.card {
    .form-control {
        @each $color, $value in $theme-colors {
            &.#{$color} {
                border-color: darken($value, 10%);
                color: rgba($white, 1);
                @include gradient-y( mix(darken($value, 15%), $value, 35%), $value);
                &:focus {
                    @include gradient-y( darken($value, 15%), darken($value, 10%));
                }
            }
        }
    }
    .btn {
        color: rgba($white,.75);
        @each $color, $value in $theme-colors {
            &.btn-outline-#{$color} {
                border-color: lighten($value, 10%);
                @include hover-focus-active() {
                    &:not(:disabled):not(.disabled),
                    &:not(:disabled):not(.disabled) {
                        &,
                        &:active {
                            border-color: lighten($value, 10%);
                            color: $white;
                        }
                        &:active {
                            background-color: darken($value, 10%);
                        }
                    }
                }
            }
            &.btn-#{$color} {
                border-color: darken($value, 10%);
                @include hover-focus-active() {
                    &:not(:disabled):not(.disabled),
                    &:not(:disabled):not(.disabled) {
                        &,
                        &:active {
                            border-color: darken($value, 10%);
                        }
                        &:active {
                            background-color: lighten($value, 10%);
                        }
                    }
                }
            }
        }
    }
}

.btn-group-toggle {
    .btn-light.active {
        color: $primary !important;
        border-color: $primary !important;
    }
}

.btn-toolbar {
  .input-group,
  .btn-group {

    .btn,
    .input-group-text {
      padding: .15rem .65rem;
    }

    .input-group-text {
      font-size: 70%;
      .icon {
        font-size: 115%;
      }
    }


    .btn {
      &.btn-outline-light {
        @include gradient-y( mix($white, $gray-100, 35%), $gray-100);
        .icon {
          opacity: .65;
        }
        @include hover {
          @include gradient-y( mix($white, $gray-50, 10%), $gray-50);
          .icon {
            opacity: 1;
          }
        }
      }

      &.btn-info {
        @include gradient-y( mix($white, $info, 35%), $info);
        @include hover {
          @include gradient-y( mix($white, $info, 20%), $info);
        }
      }
      &.btn-success {
        @include gradient-y( mix($white, $success, 35%), $success);
        @include hover {
          @include gradient-y( mix($white, $success, 20%), $success);
        }
      }
      &.btn-warning {
        @include gradient-y( mix($white, $warning, 35%), $warning);
        @include hover {
          @include gradient-y( mix($white, $warning, 20%), $warning);
        }
      }
      &.btn-danger {
        @include gradient-y( mix($white, $danger, 35%), $danger);
        @include hover {
          @include gradient-y( mix($white, $danger, 20%), $danger);
        }
      }
    }

    .form-control {
      &[type=number] {
        width: 80px;
        text-align: center;
      }
    }

    &.do-with-selected {
      min-width: 220px;
    }

  }
}

// CUSTOM-CHECKBOX
.custom-checkbox {
    &.custom-control-inline {
      margin-right: 0;
    }
    .custom-control-label {
        &:before {
          border: 1px solid $gray-300;
        }
    }
    .custom-control-input {
        &:checked ~ .custom-control-label {
            &:before {
              border-color: $custom-control-indicator-checked-bg;
            }
        }
    }
}

.table-sm,
.form-sm {
    .custom-checkbox {
        margin-top: .175rem;
        min-height: 1.15rem;
        .custom-control-label {
            &:before,
            &:after {
                width: .85rem;
                height: .85rem;
                border-radius: .15rem;
            }
        }
        .custom-control-input {
            &:checked ~ .custom-control-label {
                &:before,
                &:after {
                    width: .85rem;
                    height: .85rem;
                    border-radius: .15rem;
                }
            }
        }
    }
}
