
// MEDIA-PLAYER
.breathing-ball-btn,
.exercise-video {
    background-image: url('../img/video/maria-baumgarten-yogahochzwei-koeln-16zu9.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .js-youtube-trigger > .icon {
        position: absolute;
        width: 48px;
        height: 48px;
        font-size: 18px;
        background-color: rgba(255,255,255,.5);
        border-radius: 24px;
        margin-left: -24px;
        margin-top: -24px;
        left: 50%;
        top: 50%;
        padding: 12px 15px;
        &:before {
            margin-right: 0;
        }
    }
}
.breathing-ball-btn {
    background-image: url('../img/video/poster-img-breathing-ball_lg.jpg');
}
