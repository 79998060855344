// MODAL
// extend bootstrap/_modal.scss

.modal-content {
		background-color: $gray-50;
		border: 1px solid $gray-100;
		box-shadow: none;
}

.modal-header {
		align-items: center;
		background-color: $gray-100;
		color: $gray-500;
		border-bottom: 1px solid $gray-100;
}

// Title text within header
.modal-title {
  	margin-bottom: 0;
  	line-height: $modal-title-line-height;
		font-family: $font-family-condensed;
		font-weight: 400;
		letter-spacing: .05em;
		text-transform: uppercase;
}

// Modal body
.modal-body {
  	padding: $modal-inner-padding;
}

// Footer (for actions)
.modal-footer {
  	border-top: 1px solid $gray-100;
		background-color: $gray-100;
}

// video-modal & breathing-ball-modal
.breathing-ball-modal,
.video-modal {

		.modal-content {
				background-color: transparent;
			  border: 1px solid transparent;
			  border-radius: 0;
			  box-shadow: none;
		}

		// Modal header
		// Top section of the modal w/ title and dismiss
		.modal-header {
				// display: none;
			  border-bottom: 1px solid transparent;
				background-color: transparent;
		}

		.modal-body {
				background-color: $white;
		}

		.modal-footer {
		  	border-top: 1px solid transparent;
				background-color: transparent;
		}
}

.breathing-ball-modal {
		.modal-body {
				background-color: transparent;
		}
}

// Modal background
.modal-backdrop {
	  background-color: $modal-backdrop-bg;
}

.video-modal-open .modal-backdrop {
		background-color: $gray-200;
		&.show { opacity: 1; }
}





// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

// Scale up the modal
@include media-breakpoint-up(sm) {
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    max-width: $modal-md;
    margin: $modal-dialog-margin-y-sm-up auto;
  }

  .modal-dialog-scrollable {
    max-height: subtract(100%, $modal-dialog-margin-y-sm-up * 2);

    .modal-content {
      max-height: subtract(100vh, $modal-dialog-margin-y-sm-up * 2);
    }
  }

  .modal-dialog-centered {
    min-height: subtract(100%, $modal-dialog-margin-y-sm-up * 2);

    &::before {
      height: subtract(100vh, $modal-dialog-margin-y-sm-up * 2);
      height: min-content;
    }
  }

  .modal-content {
    @include box-shadow($modal-content-box-shadow-sm-up);
  }

  .modal-sm { max-width: $modal-sm; }
}

@include media-breakpoint-up(lg) {
  .modal-lg,
  .modal-xl {
    max-width: $modal-lg;
  }
}

@include media-breakpoint-up(xl) {
  .modal-xl { max-width: $modal-xl; }
}
