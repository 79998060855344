/*
*  APP
*  assets/scss/app.scss
*/

/*
* FONTS
*/
@import './layout/fonts';

/*
* BOOTSTRAP
* Variables and overrides
*/
@import '~bootstrap/scss/functions';
@import './bootstrap/variables';
:root {
		--font-family-serif: #{inspect($font-family-serif)};
		--font-family-condensed: #{inspect($font-family-condensed)};
		--font-family-sans-serif: #{inspect($font-family-sans-serif)};
		--font-family-monospace: #{inspect($font-family-monospace)};
}
@import '~bootstrap';
@import './bootstrap/form';
/* ----------- */

/*
* ICONS
*/
@import './icons/entypo';
@import './icons/entypo-codes';
@import './icons/roles';
@import './icons/svgicons';

/* ----------- */

/*
* MIXINS
* Incl. all
*/
@import './mixins/mixins';
/*
* Incl. custom
*/
// @import './mixins/caret';
// @import './mixins/tab-focus';
// @import './mixins/svg-tile-background';
// @import './mixins/contextual-background';
/* ----------- */

/*
* UTILITIES
* Incl. all
*/
// @import './utilities/utilities';
/*
* Incl. custom
*/
@import './utilities/pusher';
/* ----------- */

/*
* LAYOUT
*/
@import './layout/type';
@import './layout/navbar';
@import './layout/sidebar';
@import './layout/frontend';
@import './layout/base';
/* ----------- */

/*
* COMPONENTS
*/
@import './components/chosen';
@import './components/card';
@import './components/report';
@import './components/buttons';
@import './components/question-page';
@import './components/gamification';
@import './components/mediaplayer';
@import './components/modal';
/* ----------- */
