/**
* MIXINS / CONTEXTUAL-BACKGROUND
* assets/scss/mixins/_contextual-background.scss
*/

@mixin bg-variant($parent, $color) {
	  #{$parent} {
	    	border-color: darken($color, 10%) !important;
	    	background-color: rgba($color,.25) !important;
	  }
	  a#{$parent},
	  button#{$parent} {
		    @include hover-focus {
		      	background-color: rgba($color,.35) !important;
		    }
	  }
}

@mixin bg-gradient-variant($parent, $color) {
	  #{$parent} {
	    	background: $color linear-gradient(180deg, mix($body-bg, $color, 15%), $color) repeat-x !important;
	  }
}
