/* ROBOTO */

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/roboto/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
       url('../fonts/roboto/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto/roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/roboto/roboto-v20-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
       url('../fonts/roboto/roboto-v20-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto/roboto-v20-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto/roboto-v20-latin-300italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
       url('../fonts/roboto/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}


/* ROBOTO-CONDENSED */

/* roboto-condensed-300 - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/roboto-condensed/roboto-condensed-v18-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Condensed Light'), local('RobotoCondensed-Light'),
       url('../fonts/roboto-condensed/roboto-condensed-v18-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-condensed/roboto-condensed-v18-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-condensed/roboto-condensed-v18-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-condensed/roboto-condensed-v18-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-condensed/roboto-condensed-v18-latin-300.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-regular - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-condensed/roboto-condensed-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto Condensed'), local('RobotoCondensed-Regular'),
       url('../fonts/roboto-condensed/roboto-condensed-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-condensed/roboto-condensed-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-condensed/roboto-condensed-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-condensed/roboto-condensed-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-condensed/roboto-condensed-v18-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}


/* ROBOTO-SLAB */

/* roboto-slab-200 - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/roboto-slab/roboto-slab-v12-latin-200.eot'); /* IE9 Compat Modes */
  src: local('Roboto Slab Light'), local('RobotoSlab-Light'),
       url('../fonts/roboto-slab/roboto-slab-v12-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-slab/roboto-slab-v12-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-slab/roboto-slab-v12-latin-200.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-slab/roboto-slab-v12-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-slab/roboto-slab-v12-latin-200.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}
/* roboto-slab-300 - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/roboto-slab/roboto-slab-v12-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Slab Light'), local('RobotoSlab-Light'),
       url('../fonts/roboto-slab/roboto-slab-v12-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-slab/roboto-slab-v12-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-slab/roboto-slab-v12-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-slab/roboto-slab-v12-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-slab/roboto-slab-v12-latin-300.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}
/* roboto-slab-regular - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-slab/roboto-slab-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto Slab'), local('RobotoSlab-Regular'),
       url('../fonts/roboto-slab/roboto-slab-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-slab/roboto-slab-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-slab/roboto-slab-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-slab/roboto-slab-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-slab/roboto-slab-v12-latin-regular.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}


/* ROBOTO-MONO */

/* roboto-mono-300 - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/roboto-mono/roboto-mono-v12-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Mono Light'), local('RobotoMono-Light'),
       url('../fonts/roboto-mono/roboto-mono-v12-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-mono/roboto-mono-v12-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-mono/roboto-mono-v12-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-mono/roboto-mono-v12-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-mono/roboto-mono-v12-latin-300.svg#RobotoMono') format('svg'); /* Legacy iOS */
}
/* roboto-mono-regular - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-mono/roboto-mono-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto Mono'), local('RobotoMono-Regular'),
       url('../fonts/roboto-mono/roboto-mono-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-mono/roboto-mono-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-mono/roboto-mono-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-mono/roboto-mono-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-mono/roboto-mono-v12-latin-regular.svg#RobotoMono') format('svg'); /* Legacy iOS */
}
