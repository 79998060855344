/**
* MIXINS / TAB-FOCUS
* assets/scss/mixins/_tab-focus.scss
*/

@mixin tab-focus( $bg: transparent ) {
    // Default
    outline: 0;
    background-color: $bg;
    // WebKit
    outline: 0 none -webkit-focus-ring-color;
    outline-offset: 0px;
}

@mixin kill-tab-focus( ) {
    @include hover-focus() {
        // Default
         outline: 0;
         // WebKit
         outline: 0 none -webkit-focus-ring-color;
         outline-offset: 0px;
         box-shadow: none;
    }
}
