/**
*  TYPE
*  layout/_type.scss
*
*  Global typography rules
*/

// FONTS
.serif,
.text-serif      { font-family: $font-family-serif !important; }
.sans,
.sans-serif,
.text-sans-serif { font-family: $font-family-sans-serif !important; }
.condensed,
.text-condensed  { font-family: $font-family-condensed !important; }
.mono,
.monospace,
.text-monospace  {
    font-family: $font-family-monospace !important;
    font-size: 80% !important;
}

.bold       { font-weight: $font-weight-bold !important; }

// FONT-SIZE
.text-lg    { font-size: $font-size-lg !important; }
.text-sm    { font-size: $font-size-sm !important; }
.text-xs    { font-size: 75% !important; }

// HEADLINE DISPLAY
.display-1  { font-size: $display1-size !important; }
.display-2  { font-size: $display2-size !important; }
.display-3  { font-size: $display3-size !important; }
.display-4  { font-size: $display4-size !important; }

p {
  padding-top: ($spacer * 0.25);
}

.section-panel {
    ul {
        padding-left: 2.25rem;
        li {
            margin-left: - .75rem;
        }
    }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    font-family: $font-family-serif;
    padding-top: ($spacer * 0.25);
    margin-bottom: ($spacer);
    + h1, + .h1,
    + h2, + .h2,
    + h3, + .h3,
    + h4, + .h4,
    + h5, + .h5,
    + h6, + .h6,
    + p {
          padding-top: 0;
    }
}

h1 {
    padding-top: ($spacer * 1);
    @include media-breakpoint-down(md) {
        font-size: $font-size-base * 2;
    }
}

.lead {
    padding-top: 1rem;
    margin-bottom: 1.5rem;
}

.info-text-box {
    padding: .75rem 1rem;
    margin-left: -1rem;
    margin-right: -1rem;
    margin-top: 2rem;
    background-color: $gray-50;
    border: 1px solid $gray-100;
    font-size: $font-size-sm;
    color: $gray-700;
    font-family: $font-family-condensed;
    > p {
        margin: 0;
        padding: 0;
    }
    @include media-breakpoint-down(sm)
    {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

// display
.display-1,
.display-2,
.display-3,
.display-4 {
    font-family: $font-family-sans-serif;
    color: $brand-primary;
    padding-top: ($spacer * 1.5);
    margin-bottom: ($spacer * 0.25);
    + .display-1,
    + .display-2,
    + .display-3,
    + .display-4 {
        margin-top: 0;
    }
    + .lead {
        margin-top: ($spacer * 1.5);
        line-height: 1.6;
    }
}
abbr[title] {
    text-decoration: none;
}

blockquote,
.blockquote {
    font-family: $font-family-serif;
    font-size: $font-size-lg;
    font-weight: 300;
    font-style: italic;
    padding-left: 2rem;
    padding-top: .5rem;
    border-left: 6px solid $gray-300;
    color: $gray-500;
    margin-bottom: 1.5rem;
    .blockquote-footer {
        margin-top: 1rem;
        margin-bottom: .5rem;
        font-size: $font-size-sm;
        font-style: normal;
    }
}

// Renders something like this:
// ID 3 | NR 1 | MEIN TITEL
.info-text {
    &,
    &.info-text-sm {
        color: inherit;
        font-size: 80%;
        text-transform: uppercase;
        font-family: $font-family-condensed;
        font-weight: 400;
        .icon:before {
            font-size: 100%;
            margin-right: .25rem;
        }
        .info-text-item {
            display: inline-block;
            margin-right: .5rem;
            padding-right: .5rem;
            border-right: 1px solid $border-color;
            &:last-child {
                border-right: none;
            }
            &.title-item {
                font-weight: 600;
                border-right: none;
                margin-right: .25rem;
                padding-right: .25rem;
            }
        }
    }
    &.info-text-md {
        font-size: 90%;
        .icon:before {
            font-size: 110%;
            margin-right: .25rem;
        }
    }
    &.info-text-lg {
        font-size: 105%;
    }
}
