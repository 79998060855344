/**
 *  NOPO-900 – REPORT
 *  assets/scss/components/_report.scss
 */

// 1 REPORT-CONTAINER
.report-container {
		// background-color: $gray-50;
		border: 1px solid $border-color;
		// padding: 2.5rem 0;
		border-radius: $border-radius-lg;
		margin-bottom: 2rem;

		@include media-breakpoint-down(lg) {
				margin-bottom: 1rem;
				// padding: 1.5rem 0;
		}

		@include media-breakpoint-down(sm) {
		    border-radius: 0;
				border: none;
				border-bottom: 1px solid $border-color;
				margin-bottom: 0;
				// padding: 1rem 0;

				&:last-child {
						border-bottom: none;
				}
		}
}

// 2 REPORT CHART
.chart-item {
    @include stripes-d($gray-50, $white);
}

.chart-title {
    color: $gray-700;
    font-family: $font-family-condensed;
    text-transform: uppercase;
    letter-spacing: .05rem;
    font-weight: 600;
    font-size: $h5-font-size;
    margin-bottom: 0;
    line-height: 1;
}

// 3 CHART
.chart {
    background-color: rgba($gray-50, .75);
    border: 1px solid $gray-200;
		&.result-0 {
				background-color: rgba($success, .15);
				border-color: $success;
		}
		&.result-1 {
				background-color: rgba($warning, .15);
				border-color: $warning;
		}
		&.result-2 {
				background-color: rgba($danger, .05);
				border-color: $danger;
		}
    border-radius: $border-radius-sm;
    padding: .75rem;
    .cutoff {
        position: relative;
        width: 100%;
        height: 36px;
        > .cutoff-icon {
            font-size: 90%;
            position: absolute;
            bottom: 0;
            margin-left: - 11px;
            > .cutoff-label {
                font-size: 85%;
                font-family: $font-family-monospace;
                line-height: 1.5;
            }
        }
        > .cutoff-medium {
            left: 33%; // default
						color: darken($warning,15%);
        }
        > .cutoff-high {
            left: 66%; // default
						color: darken($danger,10%);
        }
        > .badge-result {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
    .progress {
        background: $gray-500 linear-gradient(180deg, mix($gray-500, $gray-100, 20%), $gray-100) repeat-x;
        height: 1.5rem;
				@include media-breakpoint-down(md) {
						height: 1rem;
				}
        line-height: 1.6;
        .valuenow {
            display: inline-block;
            height: 100%;
            width: 48px;
            font-size: 120%;
            margin-left: - 58px;
            text-align: right;
        }
    }
    .legende {
        .scale {
            color: $gray-700;
            font-family: $font-family-monospace;
            font-size: 85%;
        }
    }
}
.text {
    color: $gray-700;
    font-family: $font-family-condensed;
}
