/**
 *  NOPO-900 – QUESTIONS-PAGE
 *  assets/scss/components/_questions-page.scss
 *
 *  page w/ question-block
 */

$question-btn-bg-color: $gray-50;

.questions-page-intro,
.questions-page {
    // > .inner {
    //     padding-left: .75rem;
    //     padding-right: .75rem;
    // }

    .question {
        > .inner {
            &.current {

            }
        }
    }
    /*
    @include media-breakpoint-down(md) {
        margin-left: -.5rem;
        margin-right: -.5rem;
        > .inner {
            padding-left: 1.25rem;
            padding-right: 1.25rem;
        }
    }
    */
}

.question-counter {
    position: fixed;
    top: 10%;
    right: 6%;
    left: auto;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    background-color: rgba($primary,.75);
    z-index: $zindex-fixed;
    visibility: hidden;
    .label-txt-lfd {
        display: inline-block;
        font-family: $font-family-condensed;
        font-size: 1.4rem;
        color: $white;
        text-align: center;
        font-weight: 700;
        line-height: 1;
        &:before {
            content: '#';
        }
        &:after {
            content: '';
        }
    }
    .label-txt-total {
        display: inline-block;
        font-family: $font-family-condensed;
        font-size: .8rem;
        color: rgba($white,.7);
        text-align: center;
        font-weight: 700;
        line-height: 1;
        // margin-bottom: - .25rem;
        &:before {
            content: ' / ';
        }
        &:after {
            content: '';
        }
    }
    @include media-breakpoint-down(sm)
    {
        top: 8%;
        right: 4%;
        width: 60px;
        height: 60px;
        border-radius: 30px;
    }
}

// QUESTIONS
.question {
    // transition: all 0.2s linear;
    border: 1px solid rgba($gray-200, 0);
    // background-color: transparent;
    border-radius: 0;
    // margin-bottom: 1rem;

    // label-text
    .label-txt {
        // transition: all 0.2s linear;
        font-family: $font-family-sans-serif;
        font-size: 1.2rem;
        font-weight: 700;
        color: inherit;
        padding-bottom: 0;
        margin-bottom: 0;
        @include media-breakpoint-down(sm)
        {
            font-size: .75rem;
        }
    }

    .index-txt {
        font-family: $font-family-condensed;
        font-size: 1.2rem;
        font-weight: 600;
        color: $primary;
        @include media-breakpoint-down(sm)
        {
            font-size: .95rem;
        }
    }

    // question-text
    .question-txt {
        // transition: all 0.2s linear;
        font-family: $font-family-sans-serif;
        font-size: 1.2rem;
        color: $gray-600;
        font-weight: 600;
        @include media-breakpoint-down(sm)
        {
            font-size: .95rem;
        }
    }

    // options legende
    .options-legende {
        transition: all .2s linear;
        display: none;
        opacity: 0;
        // background-color: rgba($white,.75);
        // border-radius: $border-radius-sm;
        margin-bottom: .75rem;
        @include media-breakpoint-down(md) {
            margin-bottom: .75rem;
        }
        .option {
          font-family: $font-family-condensed;
          font-size: 70%;
          font-weight: 600;
        }
        .option-low {
            color: $gray-600;
        }
        .option-high {
            color: $gray-600;
        }
    }

    // question already orderly answered
    &.done {
        border: 1px solid rgba($gray-400, 0);
        // background-color: rgba($gray-400, 0);
        .label-txt,
        .question-txt {
            color: $gray-900;
        }
        .index-txt {
            color: $primary;
        }
    }

    // hover over question
    &.hover,
    &:hover {
        cursor: pointer;
        // border: 1px solid rgba($gray-400, 0);
        // background-color: rgba($gray-400, .05);
        .label-txt,
        .question-txt {
            color: $gray-900;
        }
        .index-txt {
            color: $primary;
        }
        &.done {
            // border: 1px solid rgba($gray-400, 0);
            // background-color: rgba($gray-400, .15);
            .label-txt,
            .question-txt {
                color: $gray-900;
            }
            .index-txt {
                color: $primary;
            }
        }
    }

    // only on next unanswerde question in line (the one targeted by the autoscroll)
    &.current {
        // border: 1px solid $primary;
        // background-color: rgba($primary, .15);
        .label-txt,
        .question-txt {
            color: $gray-900;
        }
        .index-txt {
            color: $primary;
        }
        .options-legende {
            display: flex;
            opacity: 1;
        }
    }
}

// THE 1 - 6 RADIO-GROUP
.btn-group {
    max-width: 100%;

    @include media-breakpoint-up(sm)
    {
        margin-left: auto;
    }

    @include media-breakpoint-up(xs)
    {
        &.card-group {
            padding: 0px;
            &.collapse {
                overflow: hidden;
                height: 1px;
                &.show {
                    height: auto;
                }
            }
            &.collapsing {
                height: 1px;
            }

            display: flex;
            flex-flow: row wrap;

            .card {
                background-color: transparent;
                border: none;
                // margin-top: .75rem;
                // margin-bottom: 2rem;
                flex: 1 0 0;
                box-shadow: none;
                @include kill-tab-focus();
                cursor: pointer;
                + .card {
                    margin-left: 0;
                    border-left: 0;
                }

                & {
                    .donut {
                        fill: $gray-400;
                        opacity: .5;
                        transform-origin: center center;
                        transform: scale(.95);
                        transition: opacity 0.2s linear, fill 0.2s linear, transform 0.2s linear;
                    }
                    > .card-img-overlay {
                        padding: 30%;
                        cursor: pointer;
                    }

                    .label {
                        width: 100%;
                        min-height: 100%;
                        margin: 0;
                        padding: 0;
                        display: table;
                        text-align: center;
                        border: none;
                        border-radius: 50%;

                        > .label-txt {
                            transition: color 0.2s linear;
                            color: $gray-400;
                            width: 100%;
                            display: table-cell;
                            vertical-align: middle;
                            line-height: 1;
                        }
                    }
                }

                > .option-txt {
                    transition: opacity .2s linear;
                    opacity: 0;
                    position: absolute;
                    font-family: $font-family-condensed;
                    font-weight: 600;
                    font-size: .70rem;
                    color: $white;
                    padding: .25rem .2rem;
                    text-transform: uppercase;
                    text-align: center;
                    white-space: nowrap;
                    bottom: 0;
                    top: 100%;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    width: auto;
                    min-height: 1.3rem;
                    line-height: 1;
                    border-radius: $border-radius-sm;
                }

                &:hover,
                &:focus,
                &.hover,
                &.active {
                    box-shadow: none;
                    z-index: 2;
                    .hover-color-1 > .label-txt { color: $color-1; }
                    .hover-color-2 > .label-txt { color: $color-2; }
                    .hover-color-3 > .label-txt { color: $color-3; }
                    .hover-color-4 > .label-txt { color: $color-4; }
                    .hover-color-5 > .label-txt { color: $color-5; }
                    .hover-color-6 > .label-txt { color: $color-6; }

                    .donut {
                        &.donut-1 { fill: $color-1; }
                        &.donut-2 { fill: $color-2; }
                        &.donut-3 { fill: $color-3; }
                        &.donut-4 { fill: $color-4; }
                        &.donut-5 { fill: $color-5; }
                        &.donut-6 { fill: $color-6; }
                        transform: scale(1);
                    }

                    .label {
                        background-color: $white;
                    }
                }

                &.hover,
                &:hover {
                    &, &.active {
                        > .option-txt {
                            opacity: 1;
                            &.hover-color-1 { background-color: rgba($color-1,1); }
                            &.hover-color-2 { background-color: rgba($color-2,1); }
                            &.hover-color-3 { background-color: rgba($color-3,1); }
                            &.hover-color-4 { background-color: rgba($color-4,1); }
                            &.hover-color-5 { background-color: rgba($color-5,1); }
                            &.hover-color-6 { background-color: rgba($color-6,1); }
                        }
                        .donut { opacity: 1; }
                    }
                }
                &.active {
                    > .option-txt {
                        opacity: 0;
                    }
                    .donut { opacity: .9; }
                }
            }
        }
    }
}

@include media-breakpoint-up(lg)
{
    .question {
        & {
            .question-body {
                transition: all .2s ease-out;
                transform: scale(1, 1);
            }
        }

        &.hover {
            .question-body {
                transform: scale(1.05, 1.05);
            }
        }
    }
}

.card-body + .questions-page > .card-body {
    padding-top: 0;
}

.questions-page {
    // QUESTIONS-GROUP-HEADER
    .content {
        // border-bottom: none;
        // border-color: $gray-100;
        .card-title {
            margin-top: 0;
            padding-bottom: 1rem;
        }
    }
}
