/**
 * 	SIDEBAR
 *	scss/layout/_sidebar.scss
 */

/**
 *	TOGGLE SIDEBAR
 *
 *	Toggeling classes '.sidebar-shown'/'.sidebar-hidden' on the main '.page' element (direct child of <body/>)
 *	toggles sidebar state. The initial state is determined by the 'aria-hidden' attribute of the toggle-button (.toggle-sidebar)
 *	in 'app/assets/_header_navbar.html.twig'. The 'current-state' is then tracked by a browser-cookie throughout the session.
 *	This is all handeled by 'js/components/app.js'.
 */

// body > #page/.page > #sidebar/.sidebar
		.sidebar {
				position: fixed;
				bottom: 0;
				top: 0;
				left: auto;
				min-height: 100%;
				max-width: 476px;
				z-index: 1;
				overflow-y: scroll;
				@include gradient-x($gray-25, $gray-50, 95%, 100%);
		}

// body > #page/.page
.page {
		overflow-x: hidden;
		display: none;
		& {
				.header .container-fluid {
						position: relative;
				}

				.navbar-brand {
						transition: margin-left .2s linear;
				}

				.toggle-sidebar {
						position: absolute;
						left: 0;
						height: 100%;
						z-index: ($zindex-popover + 10);
						border-radius: 0;
						box-shadow: none;
						border-right: 1px solid rgba($white, .25);
						background-color: rgba($black, .15);
				}

				.toggle-sidebar .icon:before {
						transition: transform .2s linear;
						transform: rotate(90deg);
						@include media-breakpoint-down(sm) {
								transform: rotate(0deg);
						}
				}

				// #header .navbar
				.header .navbar {
						position: relative;
						left: 0;
						right: 0;
						margin-left: 0 !important;
            margin-right: 0 !important;
				}

				// #sidebar | .sidebar
				.sidebar { }

				// #header .navbar,
				// #main | .main
				.header .navbar,
				.main {
						width: 100%;
						transition: width .2s linear, left .2s linear;
				}
		}

		/**
		 * SHOW SIDEBAR
		 */
		&.sidebar-shown {
				display: block;

				.toggle-sidebar {
						color: $primary !important;
				}

				// #sidebar | .sidebar
				.sidebar {
						left: auto;
						width: 83%;
				}

				// #main | .main
				.header .navbar,
				.main {
						left: 0;
						width: 100%;
				}

				.toggle-sidebar .icon:before {
						transform: rotate(90deg);
				}

				@include media-breakpoint-up(md) {
						.sidebar {
								left: auto;
								width: 28%;
						}
						.header .navbar,
						.main {
								left: 28%;
								width: 72%;
						}
				}
				@include media-breakpoint-up(lg) {
						.sidebar { width: 22%; }
						.header .navbar,
						.main {
								left: 22%;
								width: 78%;
						}
				}
				@include media-breakpoint-up(xl) {
						.sidebar {
								width: 17%;
						}
						.header .navbar,
						.main {
								left: 17%;
								width: 83%;
						}
				}
				@include media-breakpoint-down(sm) {
						.header .navbar,
						.main {
								left: 80%;
								width: 100%;
						}
						.sidebar {
								left: auto;
								width: 80%;
						}
				}
		}

		/**
		 * HIDE SIDEBAR
		 */
		&.sidebar-hidden {
				display: block;

				.toggle-sidebar {
						color: $gray-500;
				}

				.toggle-sidebar .icon:before {
						transform: rotate(0deg);
				}
				.header,
				.main {
						left: 0;
						width: 100%;
				}
				.sidebar {
						left: auto;
				}
		}

		@each $breakpoint in map-keys($grid-breakpoints)
		{
				@include media-breakpoint-up($breakpoint, $grid-breakpoints)
				{
						$height: map-get($header-heights, $breakpoint);
						$width: ($height + 24);

						.sidebar {
								padding-top: #{$height}px;
						}

						.toggle-sidebar {
								width: #{$height}px;
						}

						&.sidebar-hidden .navbar-brand {
								margin-left: #{$width}px;
						}
						&.sidebar-shown .navbar-brand {
								margin-left: 16px;
						}
				}
		}
}


/**
 * 	SIDEBAR-NAVI-STYLES
 *
 *	.sidebar
 *	–	 nav.sibebar-navi
 *	– –	 ul.nav
 *	– –	–	 li.nav-item
 *	– –	– –	 a.nav-link
 */

// body > #page/.page > #sidebar/.sidebar
		.sidebar {
				// nav.sidebar-navi
				.sidebar-navi {
						height: auto;
						min-height: 100%;
				}
				.nav {
						max-width: 100%;
						&.restricted-access {
								.nav-title {
										// color: $gray-400;
								}
						}

						// The 'product navi' (dark-blue)
						// ul.nav.product
						&.product {
								@include gradient-x(rgba($primary-dark,.95), rgba($primary-dark,1), 95%, 100%);
								.nav-item,
								.nav .nav-item {
										> .nav-link {
												padding-top: 1rem;
												padding-bottom: 1rem;
												color: rgba($white,.5);
												border-top: none;
												.icon-lock {
														display: none;
												}
												&:hover,
												&:focus {
														color: rgba($white,.75);
														@include gradient-x(rgba($black,.1), rgba($black,.2), 95%, 100%);
												}
												&.active {
														&,
														&:hover,
														&:focus {
																color: $white;
																@include gradient-x(rgba($black,.25), rgba($black,.35), 95%, 100%);
														}
												}
										}
										&:first-child > .nav-link {
												border-top: none;
										}
										&.nav-dots > .nav-link {
												padding-top: .5rem;
												padding-bottom: .5rem;
										}
								}
						}
				}

				.nav-item,
				.nav-link {
						max-width: 100%;
				}
				.nav-title {
						padding-left: 1.75rem;
						margin-bottom: 0;
						margin-top: 0;

				}
				.nav-title .icon,
				.nav-link .icon {
						font-size: 85%;
				}
				.nav-header .client-logo,
				.nav-header .product-logo {
						width: 42px;
						height: 42px;
						border-radius: 21px;
						font-size: 18px;
						padding: 6px;
				}
				.level-0 .nav-link,
				.level-0 .nav-title
				{
						font-size: 85%;
						padding-left: 1.25rem;
						text-transform: uppercase;
						font-family: $font-family-condensed;
						font-weight: 400;
						letter-spacing: .1em;
						color: $gray-700;
				}
				.level-0 > .nav-item > .nav-title
				{
						padding-top: 1rem;
						padding-bottom: 1rem;
				}
				.level-0 > .nav-item > .nav-link
				{
						padding-top: .75rem;
						padding-bottom: .75rem;
						color: $gray-700;
						&.nav-header {
								font-size: 90%;
								font-family: $font-family-serif;
								padding-left: .75rem;
								padding-top: 1rem;
								padding-bottom: 1rem;
								@include gradient-x(rgba($white,1), rgba($white,.75), 95%, 100%);
						}

						&.context-dark {
								color:  $gray-100;
								@include gradient-x(rgba($black,.30), rgba($black,.35), 95%, 100%);
						}

						&:hover,
						&:focus {
								color: $primary;
								// @include gradient-x($primary, darken($primary,5%), 95%, 100%);
								&.nav-header {
										color:  $primary;
								}

								&.context-dark {
										color:  $white;
										@include gradient-x(rgba($black,.55), rgba($black,.6), 95%, 100%);
								}
						}
						&.active {
								&,
								&:hover,
								&:focus {
										color: $primary;
										@include gradient-x(rgba($white,1), rgba($white,.75), 95%, 100%);

										&.context-dark {
												color:  $white;
												@include gradient-x(rgba($black,.55), rgba($black,.6), 95%, 100%);
										}
								}
						}
						&.disabled {
								&,
								&:hover,
								&:focus {
										color: rgba($white,.5);
										background-color: rgba($white,.15);
										.icon-lock {
												display: inline-block;
												font-size: 85%;
										}
								}
						}
				}
				.level-0 .level-1 {

				}
				.level-0 {
						.active .level-1 {
								width: 100%;
								height: auto;
								max-height: 100%;
								+ .level-1 {
										padding-top: 2px;
								}
						}

						&.product {
								// hide .level-1 when parent is not active (.active) in product-navi
								.level-1 {
										transition: max-height .3s ease-in;
										overflow: hidden;
										max-height: 0;
								}
								.active .level-1 {
										height: auto;
										max-height: 100%;
										@include gradient-x(rgba($black,.25), rgba($black,.35), 95%, 100%);
										.nav-item > .nav-link {
												border: none;
												&:hover,
												&:focus {
														border: none;
														color: rgba($white,.75);
														// @include gradient-x($primary, darken($primary,5%), 95%, 100%);
														@include gradient-x(rgba($black,.25), rgba($black,.35), 95%, 100%);
												}
										}
										.nav-item > .active {
												&,
												&:hover,
												&:focus {
														border: none;
														color: $white;
														@include gradient-x(rgba($black,.3), rgba($black,.4), 95%, 100%);
												}
										}
								}
						}
				}

				.level-0 .level-1 .nav-item {
						width: 100%;
				}
				.level-0 .level-1 .nav-link {
						width: 100%;
						font-size: 85%;
						padding-top: .5rem;
						padding-bottom: .5rem;
						text-transform: none;
						letter-spacing: 0.02em;
						font-family: $font-family-serif;
						border-bottom: 1px solid $white;
						color: $gray-500;
						font-weight: 300;
						&:hover,
		        &:focus {
								color: rgba($primary,.85);
								background-color: rgba($primary,.1);
						}

						&.active {
								font-weight: 400;
								&,
								&:hover,
								&:focus {
										color: $primary;
										background-color: rgba($gray-800,0);
								}
						}
				}
		}

		// add class '.show-on-hover' or '.hide-on-hover' to icons, etc. to ... you know
		.nav-link {
				.show-on-hover {
						display: none;
				}
				&:hover,
				&:focus {
						.show-on-hover {
								display: inline-block;
						}
						.hide-on-hover {
								display: none;
						}
				}
		}
