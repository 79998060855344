/**
*   PUSHER Utility
*   utilities/_pusher.scss
*/

$footer-height: 100px !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
 xxl: 1600px
) !default;

$header-heights: (
  xs: 44,
  sm: 48,
  md: 50,
  lg: 52,
  xl: 54,
 xxl: 60
) !default;

.pusher {
    display: block;
    width: 100%;

    &.hero-pusher {
        height: 15%;
        @include media-breakpoint-down(md) {
            height: 10%;
        }
        @include media-breakpoint-down(sm) {
            height: 1rem;
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            $height: map-get($header-heights, $breakpoint);
            &.header-pusher {
                height: #{$height}px;
            }
        }
    }

    &.footer-pusher {
        height: $footer-height;
    }

    &.push-1 { height: .25rem; }
    &.push-2 { height: .5rem; }
    &.push-3 { height: 1rem; }
    &.push-4 { height: 2rem; }
    &.push-5 { height: 4rem; }
    &.push-6 { height: 8rem; }
    &.push-7 { height: 16rem; }
    &.push-8 { height: 32rem; }
}
