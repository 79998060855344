$icon-font-path: "../fonts/entypo/font/";
//** File name for all font files.
$icon-font-name: "entypo-plus";
//** Element ID within SVG icon file.
$icon-font-svg-id: "entypo-plus";

// Import the fonts
@font-face {
    font-family: 'entypo-plus';
    src: url('#{$icon-font-path}#{$icon-font-name}.eot');
    src: url('#{$icon-font-path}#{$icon-font-name}.eot?#iefix') format('embedded-opentype'),
         url('#{$icon-font-path}#{$icon-font-name}.woff2') format('woff2'),
         url('#{$icon-font-path}#{$icon-font-name}.woff') format('woff'),
         url('#{$icon-font-path}#{$icon-font-name}.ttf') format('truetype'),
         url('#{$icon-font-path}#{$icon-font-name}.svg##{$icon-font-svg-id}') format('svg');
}
[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "entypo-plus";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    // margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */

    /* you can be more comfortable with increased icons size */
    font-size: 120%;

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    // text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);
}
[class^="icon-"],
[class*=" icon-"] {
    &.pos-in-gutter {
        margin-left: -($grid-gutter-width * .25);
        margin-right: -($grid-gutter-width * .25);
    }
}

@mixin entypo ( $code: '\e806', $color: $black, $size: 1rem ) {
    font-family: "entypo-plus";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	  font-size: $size;
	  width: $size;
	  height: $size;
	  margin-right: 0;
	  color: $color;
    content: $code;
}
