/*
 *  FRONTEND.SCSS
 *  assets/scss/layout/frontend.scss
 *
 *  Application frontend styles.
 */

// .page
.app-index {
		.media .product-logo {
  			width: 72px;
  			height: 72px;
  			margin-top: 28px;
  			@include media-breakpoint-up(md) {
  					width: 108px;
  					height: 108px;
  					margin-top: 14px;
  			}
  			@include media-breakpoint-up(lg) {
  					margin-top: 0;
  					width: 144px;
  					height: 144px;
  			}
  				display: block;
  				text-align: center;
		}
}

.client-logo {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    text-align: center;
		@include media-breakpoint-up(md) {
				width: 72px;
				height: 72px;
				border-radius: 36px;
		}
    &.client-logo-default {
        padding: 12px;
        border: 2px solid #ddd;
        background-color: #fff;
        font-size: 28px;
        font-family: $font-family-condensed;
        color: $primary;
    }
}

// NOPO-900 APP BACKGROUND
.nopo-900-bg {
    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-image: url('../../img/svg/cleaned/bg-lines.svg');
        background-repeat: no-repeat;
      	background-position: center center;
        background-size: 600px 1000px;
        background-attachment: fixed;
        opacity: .35;
    }
    max-width: 600px;
    background: rgba($white,.45) linear-gradient(0deg, mix(rgba($info,.5), rgba($white,.45), 100%), rgba($white,.45)) repeat-y fixed !important;

    @include media-breakpoint-up(md) {
        &:before {
            background-size: 800px 1330px;
        }
        max-width: 800px;
    }

    @include media-breakpoint-up(lg) {
        &:before {
            background-size: 1024px 1660px;
        }
        max-width: 1024px;
    }
}

// Make cards invisible on phones
.card.parent-card {
		@include media-breakpoint-down(md) {
				background-color: rgba($white, 0);
				border-radius: 0;
				border: none;
				box-shadow: none;

				.card-header,
				.card-footer {
						background-color: rgba($white, 0);
				}
		}
}

// Make block-styled submit buttons on phones
.submit-btn {
		@include media-breakpoint-down(md) {
				width: 100%;
		}
}

// Anamnesis-card disabled
.card.anamnesis-card {
		.media .product-logo {
				display: block;
				width: 60px;
				height: 60px;
				border-radius: 30px;
				text-align: center;
				@include media-breakpoint-up(md) {
						width: 72px;
						height: 72px;
						border-radius: 36px;
				}
				&.product-logo-default {
						padding: 12px;
						border: 2px solid #ddd;
						background-color: #fff;
						font-size: 28px;
						font-family: $font-family-condensed;
						color: $primary;
				}
		}

		&.disabled {
				background-color: $gray-50;
				.media .product-logo {
						opacity: .5;
				}
		}
}

.exercise-item {
		// cursor: pointer;
}
