/**
* MIXINS / SVG-TILE-BACKGROUND
* assets/scss/mixins/_svg-tile-background.scss
*/

@mixin svg-bg( $size: 10px ) {
	  background-repeat: repeat;
	  background-size: $size $size;
	  background-position: center center;
}

@mixin stripes-h ( $background: $gray-800, $color: $black, $size: 12px ) {
	  background-image: str-replace(url("data:image/svg+xml;encoding=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Cpath fill='#{$color}' d='M12,1H0V0h12V1z M12,3H0v1h12V3z M12,6H0v1h12V6z M12,9H0v1h12V9z'/%3E%3C/svg%3E"), "#", "%23");
	  background-color: $background;
	  @include svg-bg($size);
}

@mixin stripes-d ( $background: $gray-800, $color: $black, $size: 12px ) {
	  background-image: str-replace(url("data:image/svg+xml;encoding=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Cpath fill='#{$color}' d='M1,0'/%3E%3Cpolygon fill='#{$color}' points='0,2 10,12 12,12 0,0'/%3E%3Cpolygon fill='#{$color}' points='4,0 12,8 12,6 6,0'/%3E%3Cpolygon fill='#{$color}' points='0,8 4,12 6,12 0,6'/%3E%3Cpolygon fill='#{$color}' points='10,0 12,2 12,0'/%3E%3C/svg%3E"), "#", "%23");
	  background-color: $background;
	  @include svg-bg($size);
}

// – background-color
@mixin bg-color($color: $white, $alpha: 1) {
    $color-rgba: rgba(red($color), green($color), blue($color), $alpha);
    background-color: $color-rgba;
}

// – color
@mixin type-color($color: $white, $alpha: 1) {
    $color-rgba: rgba(red($color), green($color), blue($color), $alpha);
    color: $color-rgba;
}

// – border-color
@mixin border-color($color: $white, $alpha: 1) {
    $color-rgba: rgba(red($color), green($color), blue($color), $alpha);
    border-color: $color-rgba;
}
