/**
 *  BASE
 *
 *  file: assets/scss/layout/_base.scss
 */

$card-header-bg-color: $white;
$footer-height: 50px;

html,
body {
    overflow-x: hidden;
    max-width: 100%;
    min-height: 100vh;
    &.main {
        .hidden-on-main {
            display: none !important;
        }
    }
    &.admin {
        .hidden-on-admin {
            display: none !important;
        }
    }
}

html {
    font-size: 14px;
    min-height: 100vh;
}

@include media-breakpoint-up(sm) {
    html {
        font-size: 14px;
    }
}

@include media-breakpoint-up(md) {
    html {
        font-size: 15px;
    }
}

@include media-breakpoint-up(lg) {
    html {
        font-size: 15px;
    }
}
@include media-breakpoint-up(xl) {
    html {
        font-size: 16px;
    }
}
@include media-breakpoint-up(xxl) {
    html {
        font-size: 16px;
    }
}

// SPINNER
// use w/ entypo icon-class (f.e. .icon-cycle .spinner .spin)
.spinner { }

.spin {
    &:before {
        animation: spin 1.5s infinite linear;
        display: inline-block;
    }
}
@keyframes spin {
    0% {
        transform: rotate(359deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

// BACKDROP
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex-modal-backdrop;
    width: 100vw;
    height: 100vh;
    background-color: rgba($black,.95);

    // Fade for backdrop
    &.fade { opacity: 0; }
    &.show { opacity: 1; }
}

.backdrop-open {
    // Kill the scroll on the body
    overflow: hidden;
}


@each $breakpoint in map-keys($grid-breakpoints)
{
    @include media-breakpoint-up($breakpoint, $grid-breakpoints)
    {
        $height: map-get($header-heights, $breakpoint);
        $icon-height: ($height - 12);
        .nopo-900,
        .nopo-900-pos {
            @include svg('logo-nopo-900', auto, ($icon-height - 12));
        }
        .icon-900-pos {
            @include svg('900-icon-pos', $icon-height );
            top: 50%;
            position: absolute;
            margin-top: -#{($icon-height * .5)}px;
        }
        .nopo-900-neg {
            @include svg('logo-nopo-900-neg', auto, ($icon-height - 12));
        }
        .icon-900-neg {
            position: absolute;
            @include svg('900-icon-neg', $icon-height);
            top: 50%;
            margin-top: -#{($icon-height * .5)}px;
        }
        .product-logo {
            height: #{$icon-height}px;
            width: auto;
            max-width: 100%;
            display: block;
            text-align: center;
        }
        .header {
            .navbar {
                padding: 0;
                height: #{$height}px;
            }
        }

        // TOASTS
        .toaster {
            position: absolute;
            z-index: $zindex-modal;
            top: #{($height)}px;
            left: 0;
            right: 0;
            height: 0;
        }
        .toast {
            position: relative;
            top: 1rem;
            right: 16px;
            left: auto;
            min-width: 350px;
            margin-left: auto;
            font-family: $font-family-sans-serif;
            .toast-header {
                text-transform: uppercase;
                padding-left: 1rem;
                padding-right: 1rem;
                font-size: 90%;
            }
            .toast-body {
                padding: .75rem 1rem;
            }
        }
        .toast,
        .toast-info {
            border-color: $info;
            .toast-header {
                background-color: rgba($info,.15);
                color: darken($info, 25%);
            }
            .toast-body {
                color: darken($info, 25%);
            }
        }
        .toast-success {
            border-color: $success;
            .toast-header {
                background-color: rgba($success,.15);
                color: darken($success, 25%);
            }
            .toast-body {
                color: darken($success, 25%);
            }
        }
        .toast-warning {
            border-color: $warning;
            .toast-header {
                background-color: rgba($warning,.15);
                color: darken($warning, 25%);
            }
            .toast-body {
                color: darken($warning, 25%);
            }
        }
        .toast-danger,
        .toast-error {
            border-color: $danger;
            .toast-header {
                background-color: rgba($danger,.15);
                color: darken($danger, 25%);
            }
            .toast-body {
                color: darken($danger, 25%);
            }
        }
    }
}

.container,
.container-fluid {
    width: 100%;
    max-width: $page-max-width;
    &.container-xxl {
        max-width: map-get($container-max-widths, 'xxl');
    }
    &.container-xl {
        max-width: map-get($container-max-widths, 'xl');
    }
    &.container-lg {
        max-width: map-get($container-max-widths, 'lg');
    }
    &.container-md {
        max-width: map-get($container-max-widths, 'md');
    }
    &.container-sm {
        max-width: map-get($container-max-widths, 'sm');
    }
}

// <div> | #page | .page
.page
{
    min-height: 100%;
    width: 100%;
    max-width: $page-max-width;
    position: relative;
    margin: 0 auto;
    background-color: $white;
    .main {
        position: relative;
    }

    .footer {
        height: $footer-height;
    }
    .container {
        @include media-breakpoint-down(md) {
            min-width: 100%;
        }
    }
}

// .page >
    // <header> | #header | .header
    .header {
        margin: 0 auto;
        width: 100%;
        z-index: $zindex-modal-backdrop;
        max-width: $page-max-width;
        .navbar {
            position: relative;
            max-width: inherit;
        }
    }

// PAGE-CLASSES (template identifier)
.access,
.login {

}

// .page >
    // <main> | #main | .main
    .main {
        position: relative;
        display: flex;
        flex-direction: column;
        max-width: inherit;
        min-height: 100vh;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-blend-mode: multiply;
        background-attachment: fixed;
        background-color: $white;
        z-index: $zindex-sticky;
        @each $breakpoint in map-keys($grid-breakpoints)
     		{
     				@include media-breakpoint-up($breakpoint, $grid-breakpoints)
     				{
     						$header-height: map-get($header-heights, $breakpoint);
                background-position: center #{$header-height}px;
     				}
     		}
        > .container-fluid {

        }

        .messages {
            font-size: $font-size-base;
            font-family: $font-family-sans-serif;
            max-width: 320px;
            > .alert {

            }
        }
    }

// .page >
    // <main> | #main | .main
        // .section
        .section-panel-intro,
        .section-question,
        .section-panel {
            min-height: 75vh;
            width: 100%;
            @include media-breakpoint-down(sm)
            {
                padding-left: 1.75rem;
                padding-right: 1.75rem;
                + .btn {
                    margin-left: 1.75rem;
                    margin-right: 1.75rem;
                    display: block;
                }
            }

            @each $breakpoint in map-keys($grid-breakpoints)
            {
                @include media-breakpoint-up($breakpoint, $grid-breakpoints)
                {
                    $height: map-get($header-heights, $breakpoint);
                    // padding-top: #{$height}px;
                }
            }
        }
        .section-panel-intro {
            min-height: 20vh;
        }
        .section-question {
            padding-top: 2rem;
            padding-bottom: 2rem;
            min-height: 20vh;
            transition: opacity .2s ease-out;
            opacity: .25;
            left: auto !important;
            @include media-breakpoint-down(sm)
            {
                padding-left: 1.75rem;
                padding-right: 1.75rem;
            }
            .question-body {
                @include media-breakpoint-down(sm)
                {
                    margin-left: - .5rem;
                    margin-right: - .5rem;
                }
            }
            &.active,
            &.current,
            &.section-last {
                opacity: 1;
            }
        }

// .page &
.app-index ,
.app {
    .section {
        position: relative;
        width: 100%;
    }
    .main > .section {
        position: relative;
        padding-top: 16px;
        @include media-breakpoint-up(lg) {
            padding-top: 100px;
            .page-title {
                margin-bottom: 60px;
            }
        }
        @include media-breakpoint-down(md) {
            .page-title {
                font-size: $h2-font-size;
            }
        }
    }
    .hero-layer > .hero-container > .row {
        top: 250px;
    }
}

// .breadcrumb
.breadcrumb {
    border-radius: 0;
    padding: .35rem 0;
    background-color: transparent;
    border-bottom: 1px solid $gray-300;
    margin-bottom: 1.5rem;
    .breadcrumb-item {
        font-size: 80%;
        color: $gray-700;
        > a {
            color: $gray-700;
            @include hover-focus() {
                color: $gray-800;
                text-decoration: underline;
            }
        }
        &.active {
            color: $gray-800;
            font-weight: 400;
        }
        .icon {
            font-size: 85%;
        }
    }
}

.row {
    &.details-row {
        margin-bottom: 2rem;
    }
}

.toolbar {
    &.toolbar-text {

    }
}

.badge {
    padding: .35rem .5rem;
    margin: .15rem .075rem .15rem .075rem;
    letter-spacing: .05em;
    font-family: $font-family-monospace;
    font-size: 65%;
    vertical-align: middle;
    &.option-badge {
        width: 120px;
        text-transform: uppercase;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &.alias-badge {
        padding: .25rem .35rem;
        min-width: 48px;
        font-size: 70%;
        font-weight: 400;
        text-align: center;
        color: $white;
        border: 1px solid transparent;
        &.badge-cat-color-0   {
            border-color: $color-0;
            color: $color-0;
        }
        &.badge-group-color-0 { background-color: $color-0; }
        &.badge-cat-color-1   {
            border-color: $color-1;
            color: $color-1;
        }
        &.badge-group-color-1 { background-color: $color-1; }
        &.badge-cat-color-2   {
            border-color: $color-2;
            color: $color-2;
        }
        &.badge-group-color-2 { background-color: $color-2; }
        &.badge-cat-color-3   {
            border-color: $color-3;
            color: $color-3;
        }
        &.badge-group-color-3 { background-color: $color-3; }
        &.badge-cat-color-4   {
            border-color: $color-4;
            color: $color-4;
        }
        &.badge-group-color-4 { background-color: $color-4; }
        &.badge-cat-color-5   {
            border-color: $color-5;
            color: $color-5;
        }
        &.badge-group-color-5 { background-color: $color-5; }
        &.badge-cat-color-6   {
            border-color: $color-6;
            color: $color-6;
        }
        &.badge-group-color-6 { background-color: $color-6; }
    }
}

.entity-name,
.subhead,
.context-title {
    font-family: $font-family-sans-serif;
    text-transform: uppercase;
    letter-spacing: .05em;
    font-weight: 600;
    font-size: 90%;
    color: $gray-800;
    &.small {
        font-size: 80%;
        font-weight: 400;
    }
}

// exercises
.exercise-row {
    &.completed {
        color: $gray-300;
    }
    &.skipped {
        color: $gray-600;
    }
}

.exercise-buttons {
    .btn {
        border-width: 2px;
    }
}

// Dropdowns
.dropdown {
    .dropdown-menu {
        border-color: $white;
        background-color: $white;
        box-shadow: 1px 1px 10px rgba(0,0,0,.15);
        &:before {
            content: '';
            position: absolute;
            display: block;
            @include ws-caret-up(6px, $white);
            top: -6px;
            right: auto;
            left: 24px;
        }
        &.dropdown-menu-right {
            &:before {
                right: 24px;
                left: auto;
            }
        }
        .dropdown-item {
            padding-top: .5rem;
            padding-bottom: .4rem;
            font-size: 100%;
            font-family: $font-family-condensed;
        }
        .dropdown-header {
            font-size: 90%;
            color: $gray-500;
            border-bottom: 1px dotted $gray-500;
            padding: .5rem .75rem;
        }
    }
}

// Dropdowns inside tables
.tbody .dropdown .dropdown-menu,
.list-group-item .dropdown .dropdown-menu,
.details-row .dropdown .dropdown-menu {
    margin-top: .5rem;
    margin-left: -.8rem;
    &.dropdown-menu-right {
        margin-right: -.8rem;
        margin-left: 0;
    }
}
.tbody .dropdown .dropdown-menu {
    &,
    &.dropdown-menu-right {
        margin-top: -.25rem;
    }
}

// .nav-tabs
.nav {
    &.nav-tabs {
        border-bottom: none;
        box-shadow: none;
        z-index: 10;
        .nav-link,
        .dropdown .nav-link {
            padding-top: .6rem;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            color: rgba($body-color,.5);
            font-weight: 400;
            font-family: $font-family-condensed;
            letter-spacing: .03em;
            text-transform: uppercase;
            font-size: 80%;
            background-color: rgba($white,.25);
            margin-right: .25rem;
            border-color: rgba($border-color, .5);
            border-bottom-color: $border-color;
            &:hover,
            &:focus {
                border-color: rgba($border-color,1);
                border-bottom-color: $border-color;
                color: rgba($body-color,.75);
                background-color: rgba($white,.5);
            }
            .icon {
                font-size: 85%;
            }
        }
        > .nav-item > .nav-link {
            border-bottom-color: $border-color;
            &.active {
                border-color: $border-color;
                color: $primary;
                background-color: rgba($white,1);
                border-bottom-color: $white;
            }
        }
        .dropdown .dropdown-item {
            color: $body-color;
            font-family: $font-family-condensed;
            text-transform: uppercase;
            &:hover,
            &:focus {
                color: $body-color;
            }
            &.active {
                color: $white;
            }
        }
        .dropdown .dropdown-toggle {
            @include tab-focus();
            background-color: rgba($white,.5);
            @include caret;
            &:hover,
            &:focus,
            &.active {
                background-color: rgba($white,1);
            }
        }
        .dropdown .dropdown-menu {
            position: absolute;
            border: none;
            background-color: $white;
            box-shadow: $box-shadow;
            &:before {
                content: '';
                position: absolute;
                display: block;
                @include ws-caret-up(6px, $white);
                top: -6px;
                left: 50px;
                right: auto;
            }
            .dropdown-item {
                font-size: 85%;
                padding-top: .6rem;
                padding-bottom: .5rem;
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }
}

// .app- & .tab-content-container
.app-content-container,
.tab-content-container {
    @include gradient-y($white, rgba($white,0), 10%, 50%);

    .sidebar-shown &
    {
        @include media-breakpoint-down(lg)
        {
            border: none;
            border-top: 1px solid $border-color;
            border-radius: 0;
        }
    }

    &,
    .sidebar-hidden &
    {
        border: 1px solid $border-color;
        border-radius: $border-radius-lg;

        @include media-breakpoint-down(md)
        {
            border: none;
            border-top: 1px solid $border-color;
            border-radius: 0;
        }
    }
}

// .list-group
.list-group-item {
    height: 44px;
    &.ui-sortable-helper {
      // background-color: $gray-900;
      // border-bottom: 1px solid $gray-900;
    }
}
.list-group-item .handle {
    cursor: pointer;
}

// PAGINATION
.pagination {
    font-family: $font-family-condensed;
    font-weight: 400;
    .page-link {
        min-width: 34px;
        min-height: 34px;
        text-align: center;
        font-size: 0.75rem;
        padding: 0.35rem 0.25rem;
        .icon {
            vertical-align: middle;
            font-size: 80%;
        }
    }
    .page-item {
        &.pager-dots.disabled > .page-link {
            background-color: transparent;
            border-color: transparent;
            padding-left: .75rem;
            padding-right: .75rem;
        }
    }
    .btn-toolbar & {
        margin-bottom: 0;
    }
}

.btn-toolbar > .dropdown,
.btn-toolbar > .dropup {
    .dropdown-toggle {
        min-height: 34px;
        font-family: $font-family-condensed;
        font-size: 0.75rem;
        color: $gray-600;
        background-color: rgba($gray-200, .5);
        border-color: $gray-200;
        .icon {
            vertical-align: middle;
            font-size: 80%;
        }
        &:active,
        &.active {
            color: $primary-dark;
            background-color: rgba($white, 1);
            background-image: linear-gradient($white, $gray-50);
            border-color: $primary-dark;
        }
        @include hover-focus() {
            color: $primary-dark;
            background-color: rgba($white, 1);
            background-image: linear-gradient($white, $white);
        }
    }

    .dropdown-menu {
        margin-top: 6px;
        font-family: $font-family-condensed;
        min-width: 5rem;
        max-height: 300px;
        overflow-y: scroll;
        left: auto !important;
        right: 0;
        border-radius: $border-radius-sm;
        background-color: $white;
        border-color: $gray-200;
        &:before {
            content: '';
            position: absolute;
            display: none;
            @include ws-caret-up(5px, $gray-200);
            top: -5px;
            right: 14px;
            left: auto;
        }
        .dropdown-item {
            padding: .25rem .75rem;
            font-size: 85%;
            @include hover-focus-active() {
                background-color: rgba($primary-dark,.5);
                background-image: none;
                color: $white;
            }
            &.active {
                background-color: $primary-dark;
                background-image: none;
                color: $white;
            }
        }
    }
}

.btn-toolbar .btn-reset-filter {
    cursor: pointer;
    min-height: 34px;
    font-family: $font-family-condensed;
    font-size: 0.75rem;
    text-transform: none;
    color: $primary-dark;
    .icon {
        vertical-align: middle;
        font-size: 90%;
        color: $gray-300;
    }
    @include hover-focus-active() {
        &,
        &.active {
            color: $primary-dark !important;
            .icon {
                color: $primary-dark;
            }
        }
    }
}

.btn-toolbar > .dropup {
    .dropdown-menu {
        margin-top: 0;
        margin-bottom: 6px;
        &:before {
            display: none;
        }
        &:after {
            content: '';
            position: absolute;
            display: block;
            @include ws-caret-down(5px, $gray-200);
            bottom: -5px;
            top: auto;
            right: 14px;
            left: auto;
        }
    }
}

// DONUT-SVG
.svg-container {
    display: inline-block;
    position: relative;
    > .label {
        display: block;
        position: absolute;
        width: 24px;
        height: 20px;
        margin-left: -12px;
        margin-top: -10px;
        left: 50%;
        top: 50%;
        font-size: 80%;
        font-family: $font-family-condensed;
        font-weight: 600;
        color: $primary;
    }

    &.donut-svg {
        width: 40px;
        height: 40px;
        transition: width 0.2s linear, height 0.2s linear;
    }

    &.donut-svg-lg {
        width: 60px;
        height: 60px;
        transition: width 0.2s linear, height 0.2s linear;
    }
}

.donut {
    fill: $primary;
    opacity: .5;
    transition: opacity 0.2s linear, fill 0.2s linear, r 0.2s linear;
}

.btn {
    &:hover,
    &:focus {
        .donut-svg {
            .donut {
                opacity: 1;
            }
        }
        .donut-svg-lg {
            .donut {
                opacity: 1;
            }
        }
    }
}

// google address autocomplete suggestions
.pac-container {
    z-index: 1500 !important;
}
