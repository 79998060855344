/*
 *  CHOSEN OVERRIDES
 *  reset default chosen.css with custom variables to make custom skinning less annoying and timeconsuming
 *
 *  file: assets/scss/900/_chosen.scss
 */

$chosen-font-family:       $font-family-condensed !default;
$chosen-font-size:         $font-size-base !default;
$chosen-height:            $input-height !default;
$chosen-padding-x:         $input-padding-x !default;
$chosen-padding-y:         $input-padding-y !default;
$chosen-line-height:       $input-line-height !default;
$chosen-border-width:      $input-border-width !default;
$chosen-border-radius:     $border-radius-sm !default;

.chosenize {
   box-shadow:    none !important;
   border:        none !important;
   &.form-control {
      padding: 0 !important;
      margin: 0 !important;
      border-image: none !important;
      box-shadow: none !important;
      border: none !important;
      border-radius: 0 !important;
      background-color: transparent !important;
   }
}

/* SIZE
 * --------------------
 * @params: $font-size, $height, $padding-x, $padding-y, line-height, border-radius
 *
/*	-----	*/
@mixin chosen-size(
      $font-size:       $chosen-font-size,
      $height:          $chosen-height,
      $padding-x:       $chosen-padding-x,
      $padding-y:       $chosen-padding-y,
      $line-height:     $chosen-line-height,
      $border-radius:   $chosen-border-radius
   ) {
   &.chosen-container {
      font-family:   $chosen-font-family;
      font-size:     $font-size;
      line-height:   $line-height;
      font-weight:  400;
      color:        $gray-700;
      height:       100%;
      // max-height:   34px;
      & {
         .chosen-drop {
            border-image: none !important;
            box-shadow: none !important;
            @include border-bottom-radius($border-radius);
            font-size: ($font-size * .95);
            text-align: left;
            padding: 0;
         }
         .chosen-results {
            margin: 0 0 $padding-y 0;
            padding: $padding-y $padding-y 0 $padding-y;
            border-top: $border-width solid $input-focus-border-color;
         }
         .chosen-results > li {
            padding: $padding-y $padding-x;
            transition: background-color 0.1s linear, border 0.1s linear, color 0.1s linear;
            &.active-result,
            &.result-selected {
              background-image: none !important;
              margin-bottom: 1px;
              font-size: ($font-size * .9);
              &.active-result {
                  background-image: none !important;
              }
              + .group-result {
                margin-top: $padding-y;
              }
            }
            &.group-result {
               text-transform: uppercase;
               font-weight: normal;
               font-size: ($font-size * .8);
               padding-left: ($padding-y * .25);
               border-bottom: 1px dotted transparent;
               margin-bottom: 2px;
            }
         }
      }
      &.chosen-container-single {
         .chosen-drop {
            border: $border-width solid transparent;
            border-top: none;
         }
         & {
            > .chosen-single {
               box-shadow: none !important;
               background-image: none !important;
               background-clip: border-box;
               border-radius: $border-radius;
               margin: 0;
               height: 100%;
               width: 100%;
               line-height: $line-height;
               padding: $padding-y $padding-x;
               transition: background-color 0.1s linear, border 0.1s linear, color 0.1s linear;
               > div b {
                  background-image: none !important;
                  background: none !important;
                  &:before {
                     transition: color 0.1s linear;
                     @include entypo('\e83b', $input-color, $font-size);
                     position: absolute;
                     margin-top: - ($font-size * .5);
                     top: 50%;
                     right: ($padding-x * 1.5);
                  }
               }
               &.chosen-single-with-deselect > .search-choice-close {
                  &:before {
                     @include entypo('\e86f', $input-color, $font-size);
                  }
                  position: absolute;
                  z-index: 1;
                  margin-top: - ($font-size * .5);
                  top: 50%;
                  right: 2.5rem;
                  background-image: none;
               }
               @media
                  only screen and (min-resolution: 192dpi),
                  only screen and (min-resolution: 2dppx)
               {
                  > div b {
                     background-image: none !important;
                     background: none !important;
                  }
               }
               &:hover,
               &.active {
                  > div b {
                     background-image: none !important;
                     background: none !important;
                     &:before {
                        @include entypo('\e83b', $input-focus-border-color, $font-size);
                     }
                  }
                  @media
                     only screen and (min-resolution: 192dpi),
                     only screen and (min-resolution: 2dppx)
                  {
                     > div b {
                        background-image: none !important;
                        background: none !important;
                     }
                  }
               }
            }
         }
         // focus & open
         &.chosen-container-active {
            &.chosen-with-drop {
               z-index: $zindex-modal;
               > .chosen-single {
                  @include border-bottom-radius(0);
                  > div b {
                     background-image: none !important;
                     background: none !important;
                     &:before {
                        @include entypo('\e846', $input-color, $font-size);
                     }
                  }
                  @media
                     only screen and (min-resolution: 192dpi),
                     only screen and (min-resolution: 2dppx)
                  {
                     > div b {
                        background-image: none !important;
                        background: none !important;
                     }
                  }
               }
            }
            .chosen-search {
               margin: $padding-y;
               padding: 2px 1px;
               background-image: none !important;
               box-shadow: none !important;
               &:after {
                  @include entypo('\e8cd', rgba($input-color,.5), $font-size);    // magnifying-glas
                  position: absolute;
                  margin-top: - ($font-size * .5);
                  top: 50%;
                  right: $padding-y;
               }
               > input[type="text"] {
                  font-family: $chosen-font-family;
                  padding: ($padding-y * .5) $padding-y;
                  border-image: none !important;
                  background-image: none !important;
                  box-shadow: $input-box-shadow;
                  &:focus {
                     background-image: none !important;
                     box-shadow: $input-box-shadow;
                  }
               }
            }
         }
      }
      // multiselect
      &.chosen-container-multi {
          min-width: 250px;
          font-size: .825rem;
         & {
            padding: 0;
            > .chosen-choices {
               padding: ($padding-y * .5) 0 0 ($padding-y * .5);
               border-radius: $border-radius;
               box-shadow: none !important;
               background-image: none !important;
               height:       100%;
               // max-height:   34px;
               &:after {
                  // @include entypo('\e8cd', rgba($input-color,.5), $font-size);    // magnifying-glas
                  @include entypo('\e89e', rgba($input-color,.5), ($font-size * .9));    // funnel
                  position: absolute;
                  margin-top: - ($font-size * .4);
                  top: 50%;
                  right: $padding-y;
               }
               > .search-choice {
                  font-size: .775rem;
                  margin: 0 ($padding-y * .5) ($padding-y * .5) 0;
                  padding: 0.2rem 1.5rem 0.2rem 0.5rem;
                  border: none;
                  border-radius: ($border-radius * .5);
                  box-shadow: none !important;
                  background-image: none !important;
                  line-height: $line-height;
                  > .search-choice-close {
                     &:before {
                        @include entypo('\e86f', $white, $font-size * .75);
                     }
                     position: absolute;
                     margin-top: - .325rem;
                     top: 50%;
                     right: $padding-y;
                     background-image: none;
                  }
               }
               > .search-field {
                  margin: 0 ($padding-y * .5) ($padding-y * .4) 0;
                  padding: ($padding-y * .4) 0;
               }
               > .search-field > input[type="text"] {
                  height:        auto;
                  margin:        0;
                  padding-left:  ($padding-y * .5);
                  padding-right: ($padding-y * .5);
                  font-family:    $chosen-font-family;
                  background-image: none;
                  box-shadow: none !important;
                  &.default {
                     padding-left: $padding-y;
                  }
               }
            }
         }
         &.chosen-container-active {
            & {
               > .chosen-choices {
                  box-shadow: $input-focus-box-shadow;
               }
            }
            &.chosen-with-drop {
               > .chosen-choices {
                  border-bottom-left-radius: 0;
                  border-bottom-right-radius: 0;
               }
            }
            > .chosen-drop {
               border-top: none;
               > .chosen-results {
                  border-top: none;
                  > .active-result,
                  > .result-selected {
                     margin-bottom: 1px;
                     font-size: ($font-size * .95);
                     + .group-result {
                        margin-top: $padding-y;
                        margin-bottom: 2px;
                     }
                  }
                  > .group-result {
                     text-transform: uppercase;
                     font-weight: normal;
                     font-size: 90%;
                     padding-left: ($padding-y * .25);
                  }
               }
            }
         }
      }
   }
}

/* COLOR-VARIANTE
 * --------------------
 * @params: $bg, $border, $bg-focus, $border-focus
 *
/*	-----	*/
@mixin chosen-color-variante(

      $bg:              $gray-50,
      $border:          $gray-300,
      $bg-focus:        $white,
      $border-focus:    $gray-400,
      $padding-x:       $btn-padding-x,
      $padding-y:       $btn-padding-y
      // $padding-y:       .5rem
            /*
      $bg:              $input-bg,
      $border:          $input-border-color,
      $bg-focus:        $input-focus-bg,
      $border-focus:    $input-focus-border-color
      */
   ) {
   &.chosen-container {
      & {
         .chosen-results {
            border-color: $border-focus;
         }
         .chosen-results > li {
            background-color: $bg-focus;
            &.active-result {
               // color: $color;
               color: color-yiq($bg-focus);
               &.highlighted {
                  // color: $bg-focus;
                  color: color-yiq(rgba($border-focus, 1));
                  background-color: rgba($border-focus, .5);
               }
            }
            &.result-selected {
               background-color: $border-focus;
            }
            &.result-selected {
               &.active-result {
                  // color: $bg-focus !important;
                  border-color: color-yiq($border-focus);
                  background-color: $border-focus;
               }
            }
            &.group-result {
               color: rgba($border-focus, 1);
               border-color: rgba($border-focus, .5);
            }
         }
      }
      &.chosen-container-single {
         .chosen-drop {
            background-color: $bg;
            border-color: $border;
         }
         // SINGLE SELECT
         & {
            > .chosen-single {
               // w/ selection
               & {
                  // color: $color;
                  color: color-yiq($border-focus);
                  background-color: $bg;
                  border-color: $border;
                  > div b {
                     &:before {
                        color: color-yiq($border);
                     }
                  }
                  &:hover,
                  &:focus,
                  &.active {
                     // color: $color;
                     color: color-yiq($border-focus);
                     background-color: darken($bg-focus,10%);
                     border-color: darken($border-focus,10%);
                     > div b {
                        background-image: none !important;
                        background: none !important;
                        &:before {
                           // color: $color;
                           color: color-yiq($border-focus);
                        }
                     }
                  }
               }

               // nothing selected
               &.chosen-default {
                  color: color-yiq($border);
                  background-color: $bg;
                  border-color: $border;
                  font-style: normal;
                  text-transform: uppercase;
                  font-size: 80%;
                  letter-spacing: .05rem;
                  // text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.5);
                  padding: $padding-y $padding-x;
                  > div b {
                     &:before {
                        color: color-yiq($border);
                     }
                  }
                  &:hover,
                  &:focus,
                  &.active {
                     // color: $color;
                     color: color-yiq($border);
                     background-color: darken($bg,10%);
                     border-color: darken($border,10%);
                     > div b {
                        &:before {
                           // color: $color;
                           color: color-yiq($border);
                        }
                     }
                  }
               }
               &.chosen-single-with-deselect {
                  > .search-choice-close {
                     &:before {
                        transition: opacity .2s linear;
                        opacity: .5;
                        // color: $color;
                        color: color-yiq($border-focus);
                     }
                     &:hover,
                     &:focus {
                        &:before {
                           opacity: 1;
                           // color: $color;
                           color: color-yiq($border-focus);
                        }
                     }
                  }
               }
            }
         }

         // disabled
         &.chosen-disabled {
           > .chosen-single,
           > .chosen-default {
             &,
             &:focus,
             &:hover,
             &.active {
                // color: $color;
                color: color-yiq($border);
                background-color: rgba($bg,.5);
                border-color: rgba($border,.75);
                > div b {
                   &:before {
                      opacity: .5;
                   }
                }
             }
           }
         }

         // focus || tab-focus
         &.chosen-container-active {
            > .chosen-single {
               &,
               &:focus,
               &:hover,
               &.active {
                  // color: $color;
                  color: color-yiq($border-focus);
                  background-color: darken($bg-focus,10%);
                  border-color: darken($border-focus,10%);
                  > div b {
                     &:before {
                        color: color-yiq($border-focus);
                     }
                  }
               }
               &.chosen-default {
                  // color: $color;
                  color: color-yiq($border);
                  background-color: darken($bg,10%);
                  border-color: darken($border,10%);
                  font-style: normal;
                  text-transform: uppercase;
                  font-size: 80%;
                  letter-spacing: .05rem;
                  // text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.5);
                  padding: $padding-y $padding-x;
                  > div b {
                     &:before {
                        // color: $color;
                        color: color-yiq($border);
                     }
                  }
                  &:hover,
                  &:focus,
                  &.active {
                     // color: $color;
                     color: color-yiq($border);
                     background-color: darken($bg,10%);
                     border-color: darken($border,10%);
                  }
               }
            }
         }
         &.chosen-with-drop {
            .chosen-drop {
               background-color: $bg-focus;
               border-color: $border-focus;
            }
         }

         // focus && open
         &.chosen-container-active {
            &.chosen-with-drop {
               > .chosen-single {
                  &,
                  &:focus,
                  &:hover,
                  &.active {
                     // color: $color;
                     color: color-yiq($bg-focus);
                     background-color: $bg-focus;
                     border-color: $border-focus;
                     > div b {
                        &:before {
                           color: color-yiq($bg-focus);
                        }
                     }
                  }
                  &.chosen-default {
                     color: color-yiq($bg-focus);
                  }
               }
               .chosen-drop {
                  border-color: $border-focus;
                  border-top: none;
               }
            }
            .chosen-search {
               > input[type="text"] {
                  color: $input-color;
                  background-color: $input-bg;
                  &:focus {
                     color: $input-focus-color;
                     background-color: $input-focus-bg;
                     border-color: $border-focus;
                     box-shadow: $input-focus-box-shadow !important;
                  }
               }
            }
         }
      }

      // multiselect
      &.chosen-container-multi {
         & {
            > .chosen-choices {
               border-color: $border;
               background-color: $input-bg;
               > .search-choice {
                  transition: color .2s linear, background-color .2s linear;
                  // color: color-yiq($border-focus);
                  color: $white;
                  background-color: theme-color('primary');
                  border-color: theme-color('primary');
                  &:hover,
                  &:focus,
                  &.active {
                     color: $white;
                     background-color: darken(theme-color('primary'),15%);
                     border-color: darken(theme-color('primary'),15%);
                  }
                  > .search-choice-close {
                     &:before {
                        transition: opacity .2s linear;
                        opacity: .5;
                        color: color-yiq(theme-color('primary'));
                     }
                     &:hover,
                     &:focus {
                        &:before {
                           opacity: 1;
                           color: color-yiq(theme-color('primary'));
                        }
                     }
                  }
               }
               > .search-field > input[type="text"] {
                  &.default {
                     // color: $input-placeholder-color;
                     color: $border-focus;
                     // color: color-yiq(theme-color('primary'));
                  }
               }
               &.chosen-default {
                  // color: $input-placeholder-color;
                  color: $border-focus;
                  // color: color-yiq(theme-color('primary'));
               }
            }

         }
         &.chosen-container-active {
            & {
               z-index: $zindex-dropdown;
               > .chosen-choices {
                  border-color: $border-focus;
                  background-color: $input-focus-bg;
                  box-shadow: $input-focus-box-shadow !important;
               }
            }
            &.chosen-with-drop {
               > .chosen-choices {
                  border-color: $border-focus;
                  background-color: $input-focus-bg;
                  > .search-field > input[type="text"] {

                  }
               }
            }
            > .chosen-drop {
               border-color: $border-focus;
               background-color: $bg-focus;
               > .chosen-results > .result-selected {
                  color: rgba($border-focus,.35) !important; // override chosen-default which is also !important.
                  background-color: $bg-focus;
               }
            }
         }
      }
   }
}

.chosenize {
    /* CHOSEN-SIZE
     * --------------------
     * @params: $font-size, $height, $padding-x, $padding-y, line-height, border-radius
     *
    /*	-----	*/
    @include chosen-size();

    &.form-control-sm {
       @include chosen-size(
          $font-size-sm,
          $input-height-sm,
          $input-btn-padding-x-sm,
          $input-btn-padding-y-sm,
          $input-btn-line-height-sm,
          $border-radius-sm
       );
    }
    &.form-control-lg {
       @include chosen-size(
          $font-size-lg,
          $input-height-lg,
          $input-btn-padding-x-lg,
          $input-btn-padding-y-lg,
          $input-btn-line-height-lg,
          $input-border-radius-lg
       );
    }
    &.form-control {
        /* CHOSEN-COLOR-VARIANTE
         * --------------------
         * @params: $bg, $border, $bg-focus, $border-focus
         *
        /*	-----	*/
        @include chosen-color-variante(
            $white,   // bg
            theme-color('muted'),             // border
            $white,   // bg-focus
            darken(theme-color('muted'),10%)  // border-focus
        );

        &.form-control-primary {
            @include chosen-color-variante(
                theme-color('primary'),   // bg
                theme-color('primary'),             // border
                $white,   // bg-focus
                darken(theme-color('primary'),10%)  // border-focus
            );
        }

        &.form-control-light {
            @include chosen-color-variante(
                lighten(theme-color('light'),25%),   // bg
                theme-color('light'),             // border
                $white,   // bg-focus
                darken(theme-color('light'),10%)  // border-focus
            );
        }

        &.is-valid {
            @include chosen-color-variante(
                lighten(theme-color('success'),25%),   // bg
                theme-color('success'),             // border
                $white,   // bg-focus
                darken(theme-color('success'),10%)  // border-focus
            );
        }
        &.is-invalid {
            @include chosen-color-variante(
                lighten(theme-color('danger'),25),   // bg
                theme-color('danger'),             // border
                $white,   // bg-focus
                darken(theme-color('danger'),10%)  // border-focus
            );
        }
    }
}

.btn-group {
    .chosen-container {
        box-shadow: $box-shadow-sm;
    }
}
