/**
* MIXINS / CARET
* assets/scss/mixins/_caret.scss
*/

// CARET
@mixin ws-caret-down ( $width: 6px, $color: rgb(255,255,255)) {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: -($width * 1);
    vertical-align: middle;
    border-top:   $width solid $color;
    border-right: $width solid transparent;
    border-left:  $width solid transparent;
}

@mixin ws-caret-up ( $width: 6px, $color: rgb(255,255,255)) {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: -($width * 1);
    vertical-align: middle;
    border-bottom:  $width solid $color    !important;
    border-right: $width solid transparent !important;
    border-left:  $width solid transparent !important;
}
