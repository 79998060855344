/*
*  	ICONS / ROLES
*  	icons/_roles.scss
*
* 	To create ROLE-Icon class using public const App/Entity/User::ROLE_TRANS_KEYS.
*		{{ 'icon- ~ User::ROLE_TRANS_KEYS[User::ROLE_ADMIN]' }} => "icon-role_admin"
*
*		Requires '_entypo.scss' and '_entypo-codes.scss'
*/

$color-0: #c6c6c5 !default;
$color-1: #66b2e4 !default;
$color-2: #84779C !default;
$color-3: #B13966 !default;
$color-4: #bb003d !default;
$color-5: #850035 !default;
$color-6: #610822 !default;

.icon-role_user {
    @extend .icon-user;
    color: $color-0;
    &:before { font-size: 90%; }
}

.icon-role_client {
    @extend .icon-user;
    color: $color-1;
    &:before { font-size: 90%; }
}

.icon-role_editor {
    @extend .icon-tools;
    color: $color-2;
    &:before { font-size: 90%; }
}

.icon-role_partner {
    @extend .icon-star-outlined;
    color: $color-3;
    &:before { font-size: 90%; }
}

.icon-role_admin {
    @extend .icon-star;
    color: $color-4;
    &:before { font-size: 90%; }
}

.icon-role_super_admin {
    @extend .icon-star;
    color: $color-6;
    &:before { font-size: 90%; }
}
