/**
 *  NOPO-900 – BUTTON
 *  assets/scss/components/_buttons.scss
 *
 *  @mixin button-variant(
 *      $background,
 *      $border,
 *      $hover-background,
 *      $hover-border,
 *      $active-background,
 *      $active-border
 *  );
 */

// TWBS button base-class
.btn {
    // cursor: pointer;
    font-family: $font-family-condensed;
    text-transform: uppercase;
    font-weight: 400;
    @include kill-tab-focus();
    &.btn-link {
        color: inherit;
        @include hover-focus-active() {
            color: $info;
        }
    }
    &.btn-xs {
        height: 1.6rem;
        padding: 0;
        font-size: 95%;
        margin-bottom: 0;
    }
    // ROUND BUTTON base-class
    &.btn-square,
    &.btn-round {
        &:focus,
        &:active,
        &.active {
            box-shadow: none;
        }
        &,
        &.btn-base {
            width: 2.5rem;
            height: 2.5rem;
            padding: 0;
            font-size: 145%;
            &.middle {
                margin-top:  -1.25rem;
            }
            &.central {
                margin-left: -1.25rem;
            }
        }
        &.btn-lg {
            width: 3.2rem;
            height: 3.2rem;
            padding: 0;
            font-size: 160%;
            &.middle {
                margin-top:  -1.6rem;
            }
            &.central {
                margin-left: -1.6rem;
            }
        }
        &.btn-sm {
            width: 1.8rem;
            height: 1.8rem;
            padding: 0;
            font-size: 100%;
            &.middle {
                margin-top:  -0.9rem;
            }
            &.central {
                margin-left: -0.9rem;
            }
        }
        &.btn-xs {
            width: 1.6rem;
            height: 1.6rem;
            padding: 0;
            font-size: 90%;
            &.middle {
                margin-top:  -0.8rem;
            }
            &.central {
                margin-left: -0.8rem;
            }
        }
        > .icon {
            display: block;
            position: relative;
            top: -0.05rem;
            left: 0.05rem;
            &:before {
                font-size: 100%;
            }
        }
    }
    &.btn-round {
        border-radius: 50%;
    }

    @each $color, $value in $theme-colors {
        &.btn-#{$color} {
            // @include button-variant(rgba($value,.75), $value, $value, darken($value,15%), $value, darken($value,20%));
            @include button-variant($value, darken($value, 5%));
            box-shadow: $box-shadow-sm;
            &.counter {
                cursor: default !important;
                &:hover,
                &:focus {
                    border-color: $value !important;
                    color: $white !important;
                    background-color: $value !important;
                }
            }
        }
    }

    @each $color, $value in $theme-colors {
        &.btn-outline-#{$color} {
            @include button-outline-variant($value);
            &.counter {
                cursor: default !important;
                &:hover,
                &:focus {
                    border-color: $value !important;
                    color: $value !important;
                    background-color: $white !important;
                }
            }
        }
    }

    &.btn-light {
        @include button-variant(rgba($white,.85), $gray-200, $white, $gray-300, $white, $gray-200);
    }


}

// PAGER-WIDGET
// Required by pager.js (js/widgets/pager.js) to render truncated page button rows.
// F.e: "| 1 | 2 | ... | 77 | 78 |" Here buttons 3 - 76 would be rendered w/ class
// ".pager-dots" and the following hides all but the first one of these.
.pager-widget {
    .pager-dots + .pager-dots {
        display: none;
    }
}

// CHECKBOX-BTN (f.e. 'agree to term')
.checkbox-btn {
    background-color: $gray-300;
    > .icon { display: none; }
    &.active {
        @extend .btn-success;
        > .icon {
           display: inline-block;
        }
    }
}

// TOGGLE f.e. for collapse behavior
.link,
.btn,
.toggle-btn,
.toggle-link {
    .learn-more { display: none; }
    .learn-less { display: inline; }
    .icon-show { display: inline-block; }
    .icon-hide { display: none; }
    .icon-toggle-collapse:before {
        transition: transform .3s linear;
        transform: rotate(90deg);
    }
    &.collapsed {
        .learn-more { display: inline; }
        .learn-less { display: none; }
        .icon-show { display: none; }
        .icon-hide { display: inline-block; }
        .icon-toggle-collapse:before {
            transform: rotate(0deg);
        }
    }
    // position: relative;
    &.btn-sm .icon {
        position: relative;
        width: 20px;
        height: 20px;
        &:before {
            text-align: center;
        }
    }
}

.toggle-btn {
    font-weight: 400;
    color: $link-hover-color;
    &.collapsed {
        color: $gray-600;
        font-weight: 300;
        &:hover,
        &:focus {
            color: $link-hover-color;
        }
    }
}

.toggle-link {
    font-weight: 400;
    font-family: $font-family-condensed;
    color: $link-hover-color;
    &.collapsed {
        color: $gray-600;
        &:hover,
        &:focus {
            color: $link-hover-color;
        }
    }
}

.readmore-content {
    min-height: 150px;
    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 150px;
        @include gradient-y( rgba($white,0), rgba($white,1), 0%, 100%);
        transition: opacity .3s ease-out;
        opacity: 0;
    }
    &.collapse:not(.show)
    {
        display: block;
        position: relative;
        overflow: hidden;
        height: 150px;
        &:after {
            opacity: 1;
        }

    }
    &.collapsing {
        &:after {
            opacity: 1;
        }
    }
    &.show {
        &:after {
            display: none;
        }
    }
}

// LINK-COLORS
.link-info {
    color: $gray-500;
    &:hover,
    &:focus,
    &.active {
        color: $primary;
    }
    &.disabled {
        cursor: not-allowed;
        &,
        &:hover,
        &:focus {
            color: $gray-200 !important;
        }
    }
}
.link-warning {
    color: rgba($brand-warning, .65);
    &:hover,
    &:focus {
        color: $brand-warning;
    }
}
.link-danger {
    color: rgba($brand-danger, .65);
    &:hover,
    &:focus {
        color: $brand-danger;
    }
}

// THINGS W/ HOVER-CLASS SET BY JS
// All elements w/ class .hoverable receive class .hover on 'mouseenter'
.hover {
    cursor: pointer !important;
    a,
    button {
        &,
        &:hover,
        &:focus {
            color: $brand-primary;
        }
    }
}

.close {
    @include kill-tab-focus();
    .icon {
        transition: opacity .2s ease-out;
        opacity: .5;
    }
    @include hover-focus() {
        .icon {
            opacity: .75;
        }
    }
}

.close-btn {
    @extend .btn-link;
    padding: 0;
    @include kill-tab-focus();
    &:before {
        content: '';
        @include svg('ic_close_48px', 36, 36);
        transition: opacity .2s ease-out;
        opacity: .5;
    }
    @include hover-focus() {
        &:before {
            opacity: .75;
        }
    }
}
