/**
*   NAVBAR
*   layout/_navbar.scss
*
*   Requires 'mixins/_caret.scss'
*/

$page-max-width: 2500px !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
 xxl: 1600px
) !default;

$header-heights: (
  xs: 48,
  sm: 48,
  md: 50,
  lg: 52,
  xl: 54,
 xxl: 60
) !default;

.header {
    @include gradient-y($gray-950, $gray-900);;
}
.header .navbar {

    /* ADMIN/EDITOR-VIEW (light) */
    &,
    &.admin-view,
    &.editor-view {
        @extend .navbar-dark;
        @include gradient-y($gray-800, $gray-900);
        max-width: $page-max-width;
        margin-left: auto;
        margin-right: auto;

        .navbar-nav .nav-item {
            border-right: 1px solid $gray-800;
            &:last-child {
                border-right: none;
                &:first-child {
                    border-left: none;
                }
            }
        }
    }

    /* USER-VIEW (light) */
    &.user-view {
        @extend .navbar-light;
        @include gradient-y(rgba($white,.95), rgba($white,.75));
        .product-name {
            color: $gray-500;
        }
        .navbar-nav .nav-item {
            border-right-color: $gray-300;
            .nav-link {
                color: $gray-500;
                @include hover-focus-active()
                {
                    color: $gray-700 !important;
                }
            }
        }
    }
}

.navbar .navbar-nav,
.navbar .navbar-brand {
    display: flex;
    padding-top: 0;
    padding-bottom: 0;
    height: 100%;
    .product-name {
        margin: 0;
        padding: 0;
    }
}

@each $breakpoint in map-keys($grid-breakpoints)
{
    @include media-breakpoint-up($breakpoint, $grid-breakpoints)
    {
        $height: map-get($header-heights, $breakpoint);
        $icon-height: ($height - 16);
        .navbar-brand
        {
            position: relative;
            width: auto;
            height: 100%;
            max-height: #{$icon-height}px;
            padding-top: #{($icon-height * .25)}px;
            .logo {
                max-height: #{$icon-height}px;
            }
        }
    }
}

.navbar-toggler {
    border: none;
    width: 28px;
    height: 28px;
    padding: 0;
    > .hamburger {
        @include svg('hamburger-gray', 28);
    }
}

.navbar-nav .dropdown-toggle {
    @include tab-focus();
    &:after {
        display: none;
    }
    @include hover-focus-active() {
        color: $white;
    }
}

.navbar-nav .nav-item > .nav-link,
.navbar-nav .nav-item > .nav-text,
.navbar-nav .dropdown > .nav-link,
.navbar-nav .navbar-text,
.navbar-nav .dropdown-menu .dropdown-item {
    padding-top: .45rem;
    padding-bottom: .25rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: $font-family-condensed;
    font-weight: 400;
    font-size: 85%;
    text-transform: uppercase;
    @include media-breakpoint-down(sm) {
        // padding-left: .75rem;
        // padding-right: .75rem;
    }
}

.navbar-nav .nav-item {
    &.nav-item-group {
        padding-left: .5rem;
        padding-right: .5rem;

        .btn-group {
            position: relative;
            padding: 0;
        }

        .dropdown-toggle-split {
            border: none;
        }

        .nav-link {
            padding-left: .25rem;
            padding-right: .25rem;
            color: $gray-400;
            transition: color .3s ease-out;
        }

        &,
        &.active {
            color: $white;
            .active { color: $white; }
        }

        @include hover-focus-active() {
            > .nav-link { color: $gray-100; }
        }
    }
}

.navbar-nav .nav-item-title .nav-link {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-family: $font-family-serif;
    font-size: $font-size-base;
    text-transform: none;
    font-weight: 400;
    .icon {
        font-size: 95%;
    }
}

.user-view {
    .navbar-nav .nav-item {
        &.nav-item-group {
            .nav-link {
                color: $gray-300;
            }
            &,
            &.active {
                color: $gray-400;
                .active { color: $gray-400; }
            }

            @include hover-focus-active() {
                > .nav-link { color: $gray-400; }
            }
        }
    }
}

.navbar-nav .dropdown .dropdown-menu {
    position: absolute;
    border: none;
    top: 115%;
    background-color: $white;
    .dropdown-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    &.locales {
        min-width: 80px;
        margin-left: -40px;
        left: 50%;
        &:before {
            content: '';
            position: absolute;
            display: block;
            @include ws-caret-up(6px, $white);
            top: -6px;
            right: auto;
            left: 50%;
        }
        .dropdown-item {
            text-align: center;
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.navbar-nav .dropdown-menu {
    &:before {
        content: '';
        position: absolute;
        display: block;
        @include ws-caret-up(6px, $white);
        top: -6px;
        right: auto;
        left: 24px;
    }
    &.dropdown-menu-right {
        &:before {
            right: 24px;
            left: auto;
        }
    }
    &.dropdown-menu-products {
        left: 36px;
    }
    .dropdown-item {
        &.dropdown-item-products,
        &.dropdown-item-user {
            padding-top: .35rem;
            padding-bottom: .35rem;
            font-family: $font-family-serif;
            text-transform: none;
            font-size: 90%;
            color: darken($primary, 10%);
            &:focus,
            &:active,
            &:hover &:focus,
            &.active {
                color: $white;
            }
        }
        &.dropdown-item-action {
            padding-top: .2rem;
            padding-bottom: .2rem;
            font-size: 75%;
            color: $gray-400;
            font-family: $font-family-sans-serif;
            &:focus,
            &:active,
            &:hover &:focus,
            &.active {
                color: $white;
            }
        }
    }
}

.user-view .dropdown-menu {
    background-color: $white;
    &:before {
        @include ws-caret-up(6px, $white);
    }
}


.navbar-dark .navbar-nav .nav-item .nav-link,
.navbar-dark .navbar-nav .nav-item .navbar-toggler {
    border-color: $gray-800;
    @include hover-focus-active() {
        color: $white;
    }
}
.navbar-light .navbar-nav .nav-item .nav-link,
.navbar-light .navbar-nav .nav-item .navbar-toggler {
    border-color: $dark;
    @include hover-focus-active() {
        color: $primary-dark;
    }
}
