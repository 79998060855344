$icons: (
	"900-icon-neg": (
		width: 120,
		height: 120
	),
	"900-icon-pos": (
		width: 120,
		height: 120
	),
	"bg-lines": (
		width: 600,
		height: 1000
	),
	"filter-applied": (
		width: 48,
		height: 48
	),
	"filter-remove": (
		width: 48,
		height: 48
	),
	"gender-female": (
		width: 48,
		height: 48
	),
	"gender-male": (
		width: 48,
		height: 48
	),
	"gender-other": (
		width: 48,
		height: 48
	),
	"hamburger-black": (
		width: 40,
		height: 40
	),
	"hamburger-gray": (
		width: 48,
		height: 48
	),
	"hamburger-white": (
		width: 40,
		height: 40
	),
	"ic_apps_48px": (
		width: 48,
		height: 48
	),
	"ic_arrow_back_48px": (
		width: 48,
		height: 48
	),
	"ic_arrow_drop_down_48px": (
		width: 48,
		height: 48
	),
	"ic_arrow_drop_down_circle_48px": (
		width: 48,
		height: 48
	),
	"ic_arrow_drop_up_48px": (
		width: 48,
		height: 48
	),
	"ic_arrow_forward_48px": (
		width: 48,
		height: 48
	),
	"ic_arrow_upward_48px": (
		width: 48,
		height: 48
	),
	"ic_cancel_48px": (
		width: 48,
		height: 48
	),
	"ic_check_48px": (
		width: 48,
		height: 48
	),
	"ic_chevron_left_48px": (
		width: 48,
		height: 48
	),
	"ic_chevron_left_white_48px": (
		width: 48,
		height: 48
	),
	"ic_chevron_right_48px": (
		width: 48,
		height: 48
	),
	"ic_chevron_right_white_48px": (
		width: 48,
		height: 48
	),
	"ic_close_48px": (
		width: 48,
		height: 48
	),
	"ic_expand_less_48px": (
		width: 48,
		height: 48
	),
	"ic_expand_more_48px": (
		width: 48,
		height: 48
	),
	"ic_fullscreen_48px": (
		width: 48,
		height: 48
	),
	"ic_fullscreen_exit_48px": (
		width: 48,
		height: 48
	),
	"ic_last_page_48px": (
		width: 48,
		height: 48
	),
	"ic_menu_48px": (
		width: 48,
		height: 48
	),
	"ic_more_horiz_48px": (
		width: 48,
		height: 48
	),
	"ic_more_vert_48px": (
		width: 48,
		height: 48
	),
	"ic_refresh_48px": (
		width: 48,
		height: 48
	),
	"ic_subdirectory_arrow_left_48px": (
		width: 48,
		height: 48
	),
	"ic_subdirectory_arrow_right_48px": (
		width: 48,
		height: 48
	),
	"ic_unfold_less_48px": (
		width: 48,
		height: 48
	),
	"ic_unfold_more_48px": (
		width: 48,
		height: 48
	),
	"logo-nopo-900": (
		width: 312,
		height: 60
	),
	"logo-nopo-900-landingpage-neg": (
		width: 480,
		height: 138
	),
	"logo-nopo-900-landingpage-pos": (
		width: 480,
		height: 138
	),
	"logo-nopo-900-neg": (
		width: 312,
		height: 60
	),
	"logo-nopo900-subline": (
		width: 480,
		height: 200
	),
	"magic": (
		width: 36,
		height: 36
	),
	"mouse-icon": (
		width: 44,
		height: 44
	),
	"nopo-900-logo-claim-neg": (
		width: 620,
		height: 180
	),
	"nopo-900-logo-claim-pos": (
		width: 620,
		height: 180
	),
	"role-admin": (
		width: 36,
		height: 36
	),
	"role-client": (
		width: 36,
		height: 36
	),
	"role-editor": (
		width: 36,
		height: 36
	),
	"role-superadmin": (
		width: 36,
		height: 36
	),
	"role-user": (
		width: 36,
		height: 36
	),
	"user-add": (
		width: 48,
		height: 48
	),
	"user-alert": (
		width: 48,
		height: 48
	),
	"user-asterisk": (
		width: 48,
		height: 48
	),
	"user-ban": (
		width: 48,
		height: 48
	),
	"user-conversation": (
		width: 48,
		height: 48
	),
	"user-flag": (
		width: 48,
		height: 48
	),
	"user-key": (
		width: 48,
		height: 48
	),
	"user-lock": (
		width: 48,
		height: 48
	),
	"user-remove": (
		width: 48,
		height: 48
	),
	"ws-icon": (
		width: 800,
		height: 800
	),
	"ws-logo-wortbild": (
		width: 440,
		height: 110
	),
	"dog-01": (
		width: 84,
		height: 78
	),
	"dog-02": (
		width: 84,
		height: 78
	),
	"dog-03": (
		width: 84,
		height: 78
	),
	"dog-04": (
		width: 84,
		height: 78
	),
	"dog-05": (
		width: 84,
		height: 78
	),
	"dog-06": (
		width: 84,
		height: 78
	),
	"dog-muted": (
		width: 84,
		height: 78
	),
	"flag": (
		width: 40,
		height: 60
	),
	"dog-icon-01": (
		width: 84,
		height: 108
	),
	"dog-icon-02": (
		width: 84,
		height: 108
	),
	"dog-icon-03": (
		width: 84,
		height: 108
	),
	"dog-icon-04": (
		width: 84,
		height: 108
	),
	"dog-icon-05": (
		width: 84,
		height: 108
	),
	"dog-icon-06": (
		width: 84,
		height: 108
	),
	"target-01": (
		width: 50,
		height: 50
	),
	"target-02": (
		width: 50,
		height: 50
	),
	"target-03": (
		width: 50,
		height: 50
	),
	"target-04": (
		width: 50,
		height: 50
	),
	"target-05": (
		width: 50,
		height: 50
	),
	"target-06": (
		width: 50,
		height: 50
	)
);

@mixin svg($name, $width: auto, $height: auto, $size: 1){
	$icon: map-get($icons, $name);
	$sizeFactor: $size;
	@if $width != auto {
		$sizeFactor: (1 / map-get($icon, width) * $width);
	}
	@else if $height != auto {
		$sizeFactor: (1 / map-get($icon, height) * $height);
	}
	@if $width == auto {
		$width: (map-get($icon, width) * $sizeFactor);
	}
	@if $height == auto {
		$height: (map-get($icon, height) * $sizeFactor);
	}
	display: inline-block;
	background-image: url(../img/svg/cleaned/#{$name}.svg);
  background-repeat: no-repeat;
	background-position: center center;
  background-size: #{$width}px #{$height}px;
	width: #{$width}px;
	height: #{$height}px;
}

.svg-900-icon-neg {
	@include svg("900-icon-neg");
  vertical-align: middle;
}
.svg-05-900-icon-neg {
	@include svg("900-icon-neg", auto, auto, .7);
  vertical-align: middle;
}
.svg-900-icon-pos {
	@include svg("900-icon-pos");
  vertical-align: middle;
}
.svg-05-900-icon-pos {
	@include svg("900-icon-pos", auto, auto, .7);
  vertical-align: middle;
}
.svg-filter-applied {
	@include svg("filter-applied");
  vertical-align: middle;
}
.svg-05-filter-applied {
	@include svg("filter-applied", auto, auto, .7);
  vertical-align: middle;
}
.svg-filter-remove {
	@include svg("filter-remove");
  vertical-align: middle;
}
.svg-05-filter-remove {
	@include svg("filter-remove", auto, auto, .7);
  vertical-align: middle;
}
.svg-gender-female {
	@include svg("gender-female");
  vertical-align: middle;
}
.svg-05-gender-female {
	@include svg("gender-female", auto, auto, .7);
  vertical-align: middle;
}
.svg-gender-male {
	@include svg("gender-male");
  vertical-align: middle;
}
.svg-05-gender-male {
	@include svg("gender-male", auto, auto, .7);
  vertical-align: middle;
}
.svg-gender-other {
	@include svg("gender-other");
  vertical-align: middle;
}
.svg-05-gender-other {
	@include svg("gender-other", auto, auto, .7);
  vertical-align: middle;
}
.svg-hamburger-black {
	@include svg("hamburger-black");
  vertical-align: middle;
}
.svg-05-hamburger-black {
	@include svg("hamburger-black", auto, auto, .7);
  vertical-align: middle;
}
.svg-hamburger-gray {
	@include svg("hamburger-gray");
  vertical-align: middle;
}
.svg-05-hamburger-gray {
	@include svg("hamburger-gray", auto, auto, .7);
  vertical-align: middle;
}
.svg-hamburger-white {
	@include svg("hamburger-white");
  vertical-align: middle;
}
.svg-05-hamburger-white {
	@include svg("hamburger-white", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_apps_48px {
	@include svg("ic_apps_48px");
  vertical-align: middle;
}
.svg-05-ic_apps_48px {
	@include svg("ic_apps_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_arrow_back_48px {
	@include svg("ic_arrow_back_48px");
  vertical-align: middle;
}
.svg-05-ic_arrow_back_48px {
	@include svg("ic_arrow_back_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_arrow_drop_down_48px {
	@include svg("ic_arrow_drop_down_48px");
  vertical-align: middle;
}
.svg-05-ic_arrow_drop_down_48px {
	@include svg("ic_arrow_drop_down_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_arrow_drop_down_circle_48px {
	@include svg("ic_arrow_drop_down_circle_48px");
  vertical-align: middle;
}
.svg-05-ic_arrow_drop_down_circle_48px {
	@include svg("ic_arrow_drop_down_circle_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_arrow_drop_up_48px {
	@include svg("ic_arrow_drop_up_48px");
  vertical-align: middle;
}
.svg-05-ic_arrow_drop_up_48px {
	@include svg("ic_arrow_drop_up_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_arrow_forward_48px {
	@include svg("ic_arrow_forward_48px");
  vertical-align: middle;
}
.svg-05-ic_arrow_forward_48px {
	@include svg("ic_arrow_forward_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_arrow_upward_48px {
	@include svg("ic_arrow_upward_48px");
  vertical-align: middle;
}
.svg-05-ic_arrow_upward_48px {
	@include svg("ic_arrow_upward_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_cancel_48px {
	@include svg("ic_cancel_48px");
  vertical-align: middle;
}
.svg-05-ic_cancel_48px {
	@include svg("ic_cancel_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_check_48px {
	@include svg("ic_check_48px");
  vertical-align: middle;
}
.svg-05-ic_check_48px {
	@include svg("ic_check_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_chevron_left_48px {
	@include svg("ic_chevron_left_48px");
  vertical-align: middle;
}
.svg-05-ic_chevron_left_48px {
	@include svg("ic_chevron_left_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_chevron_left_white_48px {
	@include svg("ic_chevron_left_white_48px");
  vertical-align: middle;
}
.svg-05-ic_chevron_left_white_48px {
	@include svg("ic_chevron_left_white_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_chevron_right_48px {
	@include svg("ic_chevron_right_48px");
  vertical-align: middle;
}
.svg-05-ic_chevron_right_48px {
	@include svg("ic_chevron_right_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_chevron_right_white_48px {
	@include svg("ic_chevron_right_white_48px");
  vertical-align: middle;
}
.svg-05-ic_chevron_right_white_48px {
	@include svg("ic_chevron_right_white_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_close_48px {
	@include svg("ic_close_48px");
  vertical-align: middle;
}
.svg-05-ic_close_48px {
	@include svg("ic_close_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_expand_less_48px {
	@include svg("ic_expand_less_48px");
  vertical-align: middle;
}
.svg-05-ic_expand_less_48px {
	@include svg("ic_expand_less_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_expand_more_48px {
	@include svg("ic_expand_more_48px");
  vertical-align: middle;
}
.svg-05-ic_expand_more_48px {
	@include svg("ic_expand_more_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_fullscreen_48px {
	@include svg("ic_fullscreen_48px");
  vertical-align: middle;
}
.svg-05-ic_fullscreen_48px {
	@include svg("ic_fullscreen_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_fullscreen_exit_48px {
	@include svg("ic_fullscreen_exit_48px");
  vertical-align: middle;
}
.svg-05-ic_fullscreen_exit_48px {
	@include svg("ic_fullscreen_exit_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_last_page_48px {
	@include svg("ic_last_page_48px");
  vertical-align: middle;
}
.svg-05-ic_last_page_48px {
	@include svg("ic_last_page_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_menu_48px {
	@include svg("ic_menu_48px");
  vertical-align: middle;
}
.svg-05-ic_menu_48px {
	@include svg("ic_menu_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_more_horiz_48px {
	@include svg("ic_more_horiz_48px");
  vertical-align: middle;
}
.svg-05-ic_more_horiz_48px {
	@include svg("ic_more_horiz_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_more_vert_48px {
	@include svg("ic_more_vert_48px");
  vertical-align: middle;
}
.svg-05-ic_more_vert_48px {
	@include svg("ic_more_vert_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_refresh_48px {
	@include svg("ic_refresh_48px");
  vertical-align: middle;
}
.svg-05-ic_refresh_48px {
	@include svg("ic_refresh_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_subdirectory_arrow_left_48px {
	@include svg("ic_subdirectory_arrow_left_48px");
  vertical-align: middle;
}
.svg-05-ic_subdirectory_arrow_left_48px {
	@include svg("ic_subdirectory_arrow_left_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_subdirectory_arrow_right_48px {
	@include svg("ic_subdirectory_arrow_right_48px");
  vertical-align: middle;
}
.svg-05-ic_subdirectory_arrow_right_48px {
	@include svg("ic_subdirectory_arrow_right_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_unfold_less_48px {
	@include svg("ic_unfold_less_48px");
  vertical-align: middle;
}
.svg-05-ic_unfold_less_48px {
	@include svg("ic_unfold_less_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-ic_unfold_more_48px {
	@include svg("ic_unfold_more_48px");
  vertical-align: middle;
}
.svg-05-ic_unfold_more_48px {
	@include svg("ic_unfold_more_48px", auto, auto, .7);
  vertical-align: middle;
}
.svg-logo-nopo-900 {
	@include svg("logo-nopo-900");
  vertical-align: middle;
}
.svg-05-logo-nopo-900 {
	@include svg("logo-nopo-900", auto, auto, .7);
  vertical-align: middle;
}
.svg-logo-nopo-900-landingpage-neg {
	@include svg("logo-nopo-900-landingpage-neg");
  vertical-align: middle;
}
.svg-05-logo-nopo-900-landingpage-neg {
	@include svg("logo-nopo-900-landingpage-neg", auto, auto, .7);
  vertical-align: middle;
}
.svg-logo-nopo-900-landingpage-pos {
	@include svg("logo-nopo-900-landingpage-pos");
  vertical-align: middle;
}
.svg-05-logo-nopo-900-landingpage-pos {
	@include svg("logo-nopo-900-landingpage-pos", auto, auto, .7);
  vertical-align: middle;
}
.svg-logo-nopo-900-neg {
	@include svg("logo-nopo-900-neg");
  vertical-align: middle;
}
.svg-05-logo-nopo-900-neg {
	@include svg("logo-nopo-900-neg", auto, auto, .7);
  vertical-align: middle;
}
.svg-logo-nopo900-subline {
	@include svg("logo-nopo900-subline");
  vertical-align: middle;
}
.svg-05-logo-nopo900-subline {
	@include svg("logo-nopo900-subline", auto, auto, .7);
  vertical-align: middle;
}
.svg-magic {
	@include svg("magic");
  vertical-align: middle;
}
.svg-05-magic {
	@include svg("magic", auto, auto, .7);
  vertical-align: middle;
}
.svg-mouse-icon {
	@include svg("mouse-icon");
  vertical-align: middle;
}
.svg-05-mouse-icon {
	@include svg("mouse-icon", auto, auto, .7);
  vertical-align: middle;
}
.svg-nopo-900-logo-claim-neg {
	@include svg("nopo-900-logo-claim-neg");
  vertical-align: middle;
}
.svg-05-nopo-900-logo-claim-neg {
	@include svg("nopo-900-logo-claim-neg", auto, auto, .7);
  vertical-align: middle;
}
.svg-nopo-900-logo-claim-pos {
	@include svg("nopo-900-logo-claim-pos");
  vertical-align: middle;
}
.svg-05-nopo-900-logo-claim-pos {
	@include svg("nopo-900-logo-claim-pos", auto, auto, .7);
  vertical-align: middle;
}
.svg-role-admin {
	@include svg("role-admin");
  vertical-align: middle;
}
.svg-05-role-admin {
	@include svg("role-admin", auto, auto, .7);
  vertical-align: middle;
}
.svg-role-client {
	@include svg("role-client");
  vertical-align: middle;
}
.svg-05-role-client {
	@include svg("role-client", auto, auto, .7);
  vertical-align: middle;
}
.svg-role-editor {
	@include svg("role-editor");
  vertical-align: middle;
}
.svg-05-role-editor {
	@include svg("role-editor", auto, auto, .7);
  vertical-align: middle;
}
.svg-role-superadmin {
	@include svg("role-superadmin");
  vertical-align: middle;
}
.svg-05-role-superadmin {
	@include svg("role-superadmin", auto, auto, .7);
  vertical-align: middle;
}
.svg-role-user {
	@include svg("role-user");
  vertical-align: middle;
}
.svg-05-role-user {
	@include svg("role-user", auto, auto, .7);
  vertical-align: middle;
}
.svg-user-add {
	@include svg("user-add");
  vertical-align: middle;
}
.svg-05-user-add {
	@include svg("user-add", auto, auto, .7);
  vertical-align: middle;
}
.svg-user-alert {
	@include svg("user-alert");
  vertical-align: middle;
}
.svg-05-user-alert {
	@include svg("user-alert", auto, auto, .7);
  vertical-align: middle;
}
.svg-user-asterisk {
	@include svg("user-asterisk");
  vertical-align: middle;
}
.svg-05-user-asterisk {
	@include svg("user-asterisk", auto, auto, .7);
  vertical-align: middle;
}
.svg-user-ban {
	@include svg("user-ban");
  vertical-align: middle;
}
.svg-05-user-ban {
	@include svg("user-ban", auto, auto, .7);
  vertical-align: middle;
}
.svg-user-conversation {
	@include svg("user-conversation");
  vertical-align: middle;
}
.svg-05-user-conversation {
	@include svg("user-conversation", auto, auto, .7);
  vertical-align: middle;
}
.svg-user-flag {
	@include svg("user-flag");
  vertical-align: middle;
}
.svg-05-user-flag {
	@include svg("user-flag", auto, auto, .7);
  vertical-align: middle;
}
.svg-user-key {
	@include svg("user-key");
  vertical-align: middle;
}
.svg-05-user-key {
	@include svg("user-key", auto, auto, .7);
  vertical-align: middle;
}
.svg-user-lock {
	@include svg("user-lock");
  vertical-align: middle;
}
.svg-05-user-lock {
	@include svg("user-lock", auto, auto, .7);
  vertical-align: middle;
}
.svg-user-remove {
	@include svg("user-remove");
  vertical-align: middle;
}
.svg-05-user-remove {
	@include svg("user-remove", auto, auto, .7);
  vertical-align: middle;
}
.svg-ws-icon {
	@include svg("ws-icon");
  vertical-align: middle;
}
.svg-05-ws-icon {
	@include svg("ws-icon", auto, auto, .7);
  vertical-align: middle;
}
.svg-ws-logo-wortbild {
	@include svg("ws-logo-wortbild");
  vertical-align: middle;
}
.svg-05-ws-logo-wortbild {
	@include svg("ws-logo-wortbild", auto, auto, .7);
  vertical-align: middle;
}
