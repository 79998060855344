/**
 *  NOPO-900 – CARD
 *  assets/scss/components/_card.scss
 */

/*
 *  TOC
 *  ---------------------------
 *  1. BASIC CARD
 *    1.1  CARD-HEADER
 *      –  card-title inside card-header
 *      –  badge inside card-header
 *      –  card-header as card-toggle-button
 *      –  card-header "dark"
 *      –  card-header of form-card containing translatable form-fields
 *      –  card-header w/ nav-tabs
 *    1.2  CARD-BODY
 *    1.3  CARD-FOOTER
 *  2. TOGGLEABLE CARD
 *  3. TABLE-CARD
 *  4. CARD w/ SORTABLE LISTS
 *  5. CARD COLOR-THEMES
 */


//  1. BASIC CARD
// --------------------------- //
.card {
    border-color: rgba(0,0,0,.2);
    background-color: $white;
    box-shadow: $box-shadow-sm;

    &.disabled {
        background-color: rgba($white,.5);
        box-shadow: none;
        color: $gray-500;
    }
}

// 1.1  CARD-HEADER
.card-header {
    // font-family: $font-family-monospace;
    color: $level-2-default;
    min-height: 16px;
    padding: $card-spacer-y $card-spacer-x;
    border-bottom: 1px solid rgba(0,0,0,.2);
    transition: background-color .3s ease-out;

    &.inverse {
        background-color: $primary-dark;
        color: $white;
    }

    + .card-header {
        margin-top: -1px;
        border-top: 1px solid $gray-200;
    }

    // card-title inside card-header
    .card-title {
        margin: 0;
        padding: 0;
        font-weight: 400;
        font-family: inherit;
        // font-size: inherit;
        // text-transform: uppercase;
        // letter-spacing: .05em;
    }

    .card-title > .icon {
        font-size: 85%;
    }

    // badge inside card-header
    .badge {
        font-size: 75%;
    }
}

// dark
.card-header.bg-dark,
.bg-dark > .card-header,
.card-header-dark
{
    @include gradient-y( $gray-900, $gray-950, 0%, 100%);
    border-bottom-color: rgba(0,0,0,.35);
    > .card-title {
        transition: color .25s linear;
        color: $white;
    }
}

// card-header of form-cards containing translatable form-fields
.card-header {
    color: $level-2-default;
    &.card-header-translatable {
        background-color: $body-bg;
        border-bottom: none;
        text-transform: none;
        font-family: $font-family-monospace;
    }
    &.card-header-language-tabs {
        border-top: none;
        margin-top: 0;
        padding-top: 1rem;
        padding-bottom: $card-spacer-y;
        font-family: $font-family-condensed;
        font-size: 80%;
        @include gradient-y( rgba($gray-200, 0), rgba($gray-200, .5), 25%, 100%);
    }
}

// card-header w/ nav-tabs
.card-header {
    .nav-tabs .nav-item {
        > .nav-link {
            border-color: transparent;
            background-color: $gray-200;
            padding-bottom: .45rem;
            font-size: 100%;
            border-bottom-color: $gray-200;
            &.active {
                border-color: $gray-200;
                border-bottom-color: $white;
                background-color: $white;
            }
        }
    }
}

// 1.2  CARD-BODY
.card-body {

    .card-title {
        padding-top: .75rem;
        font-size: $h3-font-size;
        font-weight: 400;
        margin-bottom: 1.5rem;
        color: $gray-800;
    }
}

// 1.3  CARD-FOOTER
.card-footer {
    min-height: 16px;
    padding: ($card-spacer-y * .5) $card-spacer-x;
    background-color: rgba($gray-900,.05);
}

    // dark
    .card-footer-dark {
        background-color: $gray-900;
        border-top-color: $gray-900;
    }


//  2. TOGGLEABLE CARD
// --------------------------- //
.card {
    .card-header-toggler {
        cursor: pointer;
        position: relative;
        @include gradient-y( $gray-25, $gray-100, 0%, 100%);
        > .card-title {
            transition: color .25s linear;
            margin-left: 1.5rem;
            color: $gray-950;
            font-size: 85%;
        }
        &:before {
            content: '';
            transition: transform .25s linear;
            @include svg('ic_chevron_right_48px', auto, auto, .45);
            display: block;
            position: absolute;
            left: .5rem;
            top: .5rem;
            transform: rotate(90deg);
        }
        &.collapsed {
            @include gradient-y( $white, $gray-25, 0%, 100%);
            border-bottom-left-radius: $border-radius-sm;
            border-bottom-right-radius: $border-radius-sm;
            > .card-title {
                color: $gray-700;
            }
            &:before {
                transform: rotate(0deg);
                opacity: .5;
            }
        }
    }
}


//  3. TABLE-CARD
// --------------------------- //
.card {
    .table {
        margin-top: .75rem;
        margin-bottom: .75rem;
    }
}

.table-card {
    background-color: transparent;
    border-color: transparent;
    .card-header,
    .card-footer {
        border-color: transparent;
        background-color: transparent;
    }
}

.table-card-bordered {
    border-color: $gray-200;
    background-color: $white;
    box-shadow: $box-shadow-sm;
    .card-header,
    .card-footer {
        border-color: $gray-200;
    }

    .table {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.license-balance-card {
    min-width: 300px;
    + .license-balance-card {
        margin-left: 1rem;
    }

}

//  4. CARD w/ SORTABLE LISTS
// --------------------------- //
.card > .list-group {

    > .list-group-item,
    > .list-group-item .list-group .list-group-item {
        overflow: hidden;
        color: $gray-800;
        background-color: $gray-50;
        .link,
        .handle {
            cursor: pointer;
            .icon:before {
                font-size: 100%;
            }
        }
        .handle {
            cursor: move;
        }

        .position {
            font-family: $font-family-condensed;
            font-weight: $font-weight-bold;
        }

        &.parent {
            background-color: $white;
        }

        &.disabled {
            color: $gray-300;
            background-color: $gray-100;
            .handle {
                cursor: not-allowed;
            }
            .handle .icon {
                color: $gray-300;
            }
            .alias-badge {
                background-color: $gray-100 !important;
                border-color: $gray-300 !important;
                color: $gray-300 !important;
            }
        }
    }

    .placeholder {
        list-style-type: none;
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-top: 1px solid $gray-300;
        border-bottom: 1px solid $gray-300;
        border-left: 1px solid $gray-300;
        border-right: 1px solid $gray-300;
        background-color: $gray-200;
    }
    .ui-sortable-helper,
    .ui-draggable-dragging {
        background-color: $gray-50;
        border-top: 1px solid $gray-200;
        border-bottom: 1px solid $gray-200;
        border-left: 1px solid $gray-200;
        border-right: 1px solid $gray-200;
    }
    .ui-draggable-dragging {
        width: 100%;
    }
}

// PRODUCT-CARD
.product-card,
.active-link-card {
    transition: background-color .3s ease-out, border-color .3s ease-out, color .3s ease-out;
    &.disabled {
        background-color: $gray-100;
        color: $gray-300;
        border-color: $gray-300;
        .form-control {
            background-color: $gray-100;
            color: $gray-300;
            border-color: $gray-300;
            cursor: default;
        }
    }
    + .active-link-card {
        margin-top: 1rem;
    }
}

//  5. CARD COLOR-THEMES
// --------------------------- //
.card {
    @each $color, $value in $theme-colors {
        &.bg-#{$color} {
            .form-control,
            .input-group-text {
                border-color: darken($value, 5%);
            }
            .form-control {
                color: darken($value, 5%);
            }
            .input-group-text {
                background-color: lighten($value, 5%);
            }
            .btn {
                @include button-variant($value, darken($value, 5%));
                box-shadow: $box-shadow-sm;
            }

            .info-text,
            .info-text > .info-text-item {
                color: darken($value, 25%);
                border-color: darken($value, 25%);
                &.title-item {
                    color: darken($value, 35%);
                }
            }

            .card-header {
                color: $white;
            }

            .card-body {
                &.product-settings {
                    background-color: darken($value, 10%);
                    border-bottom: 1px solid lighten($value, 5%);

                    .info-text,
                    .info-text > .info-text-item {
                        color: lighten($value, 25%);
                        border-color: lighten($value, 25%);
                        &.title-item {
                            color: lighten($value, 35%);
                        }
                    }
                }
            }

            .card-footer {

            }
        }
    }
}
